.just-sayin-detail {
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 14px;

  .just-sayin-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media (min-width: 576px) {
      height: 40.5px;
      align-items: center;
    }
    @media (max-width: 575px) {
      flex-direction: column;
      gap: 10px;
    }
  }

  &__nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .header-title {
      padding-bottom: 0px;
    }
    &-actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
    }
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }

    &-info {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;
    }
    &-status {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;
      .voice-cell__tag-icon {
        margin-right: 6px;
      }
      .voice-cell__tag-text {
        color: var(--text);
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  &__title {
    font-family: "Campton-Medium";
  }

  &__footer {
    display: flex;
    flex-direction: column;
    gap: 14px;
    &-info {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 14px;
    }
    &-reactions {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
    }
    &-tag {
      text-transform: capitalize;
    }
  }
}
