.notme-switch {
  width: 40px;
  height: 24px;
  background-color: var(--border);
  border: none;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  transition: 0.25s;
  justify-content: flex-start;
  align-items: center;
  padding: 2px;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
  .notme-switch-dot {
    width: 20px;
    height: 20px;
    border-radius: 12px;
    display: inline-block;
    transition: 0.25s;
    background-color: var(--white);
    -webkit-box-shadow: 3px 3px 4px -2px rgba(36, 36, 36, 0.445);
    box-shadow: 3px 3px 4px -2px rgba(36, 36, 36, 0.445);
  }
  .notme-switch-dot-checked {
    transform: translate(16px);
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

.notme-switch-checked {
  background-color: var(--green);
}

@mixin notme-switch-theme($color) {
  background-color: var($color);
}

.notme-switch-checked-blue {
  @include notme-switch-theme(--blue);
}
.notme-switch-checked-light-blue {
  @include notme-switch-theme(--light_blue);
}
.notme-switch-checked-dark-blue {
  @include notme-switch-theme(--dark_blue);
}
.notme-switch-checked-red {
  @include notme-switch-theme(--red);
}
.notme-switch-checked-light-red {
  @include notme-switch-theme(--light_red);
}
.notme-switch-checked-dark-red {
  @include notme-switch-theme(--dark_red);
}
.notme-switch-checked-green {
  @include notme-switch-theme(--green);
}
.notme-switch-checked-dark-green {
  @include notme-switch-theme(--dark_green);
}
.notme-switch-checked-yellow {
  @include notme-switch-theme(--yellow);
}
