.empty-data {
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--dark_grey);
  gap: 14px;
  &__icon {
    font-size: 34px;
  }
  &__title {
    font-family: "Campton-Medium";
    color: var(--dark_grey);
    font-size: 18px;
  }
  &__subtitle {
    color: var(--grey);
    font-size: 16px;
  }
}
