.report-step-tags {
  .categories-container {
    position: relative;
    .categories {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 26px;
      overflow-x: scroll;
      white-space: nowrap;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
