.report-banner {
  background-color: var(--cream);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  gap: 16px;
  .report-banner-text {
    color: var(--red);
    flex-shrink: 1;
    font-size: 14px;
    line-height: 14px;
  }
}
