.notme-checkbox {
  position: relative;
  padding-left: 34px;
  padding-top: 3px;
  padding-bottom: 3px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: 10px;

  &.disabled {
    cursor: not-allowed;
    color: var(--light_grey);
  }
  .notme-checkbox--input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }

  .notme-checkbox--checkmark {
    position: absolute;
    top: calc(50% - 11px);
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 3px;
    border-width: 1px;
    color: transparent;
  }
}

@mixin notme-checkbox-theme($color) {
  border: 1px solid var($color);
  background-color: var($color);
  color: var(--white);
}
@mixin notme-checkbox-theme-outline($color) {
  border-color: var($color);
  border-style: solid;
}

.notme-checkbox:hover .notme-checkbox--input ~ .notme-checkbox--checkmark {
  background-color: var(--light_grey);
}

.notme-checkbox .notme-checkbox--input:disabled ~ .notme-checkbox--checkmark {
  opacity: 0.5;
  cursor: not-allowed !important;
}
.notme-checkbox
  .notme-checkbox--input:checked
  ~ .notme-checkbox-blue--checkmark {
  @include notme-checkbox-theme(--blue);
}
.notme-checkbox
  .notme-checkbox--input:checked
  ~ .notme-checkbox-light-blue--checkmark {
  @include notme-checkbox-theme(--light_blue);
}
.notme-checkbox
  .notme-checkbox--input:checked
  ~ .notme-checkbox-dark-blue--checkmark {
  @include notme-checkbox-theme(--dark_blue);
}
.notme-checkbox
  .notme-checkbox--input:checked
  ~ .notme-checkbox-red--checkmark {
  @include notme-checkbox-theme(--red);
}
.notme-checkbox
  .notme-checkbox--input:checked
  ~ .notme-checkbox-light-red--checkmark {
  @include notme-checkbox-theme(--light_red);
}
.notme-checkbox
  .notme-checkbox--input:checked
  ~ .notme-checkbox-dark-red--checkmark {
  @include notme-checkbox-theme(--dark_red);
}
.notme-checkbox
  .notme-checkbox--input:checked
  ~ .notme-checkbox-green--checkmark {
  @include notme-checkbox-theme(--green);
}
.notme-checkbox
  .notme-checkbox--input:checked
  ~ .notme-checkbox-dark-green--checkmark {
  @include notme-checkbox-theme(--dark_green);
}
.notme-checkbox
  .notme-checkbox--input:checked
  ~ .notme-checkbox-yellow--checkmark {
  @include notme-checkbox-theme(--yellow);
}

.notme-checkbox .notme-checkbox--input ~ .notme-checkbox-blue--checkmark {
  @include notme-checkbox-theme-outline(--blue);
}
.notme-checkbox .notme-checkbox--input ~ .notme-checkbox-light-blue--checkmark {
  @include notme-checkbox-theme-outline(--light_blue);
}
.notme-checkbox .notme-checkbox--input ~ .notme-checkbox-dark-blue--checkmark {
  @include notme-checkbox-theme-outline(--dark_blue);
}
.notme-checkbox .notme-checkbox--input ~ .notme-checkbox-red--checkmark {
  @include notme-checkbox-theme-outline(--red);
}
.notme-checkbox .notme-checkbox--input ~ .notme-checkbox-light-red--checkmark {
  @include notme-checkbox-theme-outline(--light_red);
}
.notme-checkbox .notme-checkbox--input ~ .notme-checkbox-dark-red--checkmark {
  @include notme-checkbox-theme-outline(--dark_red);
}
.notme-checkbox .notme-checkbox--input ~ .notme-checkbox-green--checkmark {
  @include notme-checkbox-theme-outline(--green);
}
.notme-checkbox .notme-checkbox--input ~ .notme-checkbox-dark-green--checkmark {
  @include notme-checkbox-theme-outline(--dark_green);
}
.notme-checkbox .notme-checkbox--input ~ .notme-checkbox-yellow--checkmark {
  @include notme-checkbox-theme-outline(--yellow);
}

.notme-checkbox--checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.notme-checkbox
  .notme-checkbox--input:checked
  ~ .notme-checkbox--checkmark:after {
  display: block;
}

.notme-checkbox .notme-checkbox--checkmark:after {
  left: 5px;
  top: 2px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
