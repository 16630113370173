.report-step-places {
  display: flex;
  flex-direction: column;
  gap: 16px;
  .places-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .places-list {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .place-cell {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}
