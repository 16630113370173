.notme-button-arrow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
  border: 1px solid var(--card);
  background: var(--card);
  color: var(--text);
  &:hover {
    background: var(--lighter_grey);
    border-color: var(--lighter_grey);
  }
  .notme-button-arrow-icon {
    transition: 0.2s;
  }
  .opened {
    transform: rotate(180deg);
  }
}
