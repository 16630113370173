.report-evidence-cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  cursor: pointer;
  .report-evidence-cell-body {
    display: flex;
    flex-direction: column;
    .report-evidence-cell-body-title {
      font-family: "Campton-Bold";
      font-size: 15px;
    }
    .report-evidence-cell-body-date {
      font-size: 13px;
      color: var(--subtitle);
      display: flex;
      flex-direction: row;
      gap: 4px;
    }
    .report-evidence-cell-body-icon {
      font-size: 14px;
    }
  }
}
