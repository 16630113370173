@import "styles/_variables.scss";

.entity-selector {
  transition: 0s;
  margin: 10px;
  padding: 10px;
  border-radius: 6px;
  background-color: var(--background);
  position: relative;
  .entity-selector-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .entity-selector-container {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;
      overflow-x: hidden;
      .entity-selector-info {
        display: flex;
        flex-direction: column;
        overflow-x: hidden;

        .entity-selector-info-entity {
          font-weight: 700;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow-x: hidden;
        }
        .entity-selector-info-role {
          font-weight: 400;
          color: var(--subtitle);
          font-size: 14px;
        }
      }
    }
  }

  .entity-selector-clear-button {
    position: absolute;
    top: 10px;
    right: 24px;
    height: 18px;
    width: 18px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s;
    &:hover {
      background-color: var(--card);
    }
  }

  .entity-selector-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--subtitle);
    cursor: pointer;
    .entity-selector-button-second-icon {
      margin-top: -7px;
    }
  }
}

.entity-selector-user-interaction {
  cursor: pointer;
  &:hover {
    background-color: var(--border);
  }
}

.entity-selector-reduced {
  margin: 22.5px 10px;
}

.entity-selector-reduced-user-interaction {
  cursor: pointer;
}
