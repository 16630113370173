.profile-page {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .profile-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    &__language {
      display: flex;
      flex-direction: row;
      gap: 10px;
      .notme-input {
        flex: 1;
      }
    }
    .profile-container-duo {
      display: flex;
      flex-direction: row;
      .profile-container-duo-item {
        flex: 0 0 50%;
      }
    }
  }
}
