.post-page {
  padding: 1rem 0;

  &__buttons {
    display: flex;
    gap: 10px;
  }

  &__nav {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    .notme-button {
      padding: 12px;
      line-height: 12px;
    }
  }

  &__loader-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
    gap: 10px;
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
    gap: 10px;

    &-tag-container {
      display: flex;
      flex-direction: row;
      gap: 10px;
    }

    &-tag {
      text-transform: capitalize;
    }

    &-title {
      font-size: 24px;
      line-height: 24px;
      color: var(--text);
      font-family: "Campton-Bold";
    }

    &-info {
      font-size: 16px;
      span {
        color: var(--grey);
      }
      strong {
        color: var(--text);
      }
    }
    &-thumbnail {
      border-radius: 8px;
      overflow: hidden;
      width: 100%;
      aspect-ratio: 16/9;
      .image-loading__wrapper {
        position: relative;
      }
    }
  }

  &__content {
    margin-top: 20px;
    width: 100%;
    &-body {
      display: flex;
      flex-direction: column;
      gap: 10px;
      img {
        width: 100%;
        height: auto;
        border-radius: 8px;
        margin: 10px 0;
      }

      .youtube-container {
        position: relative;
        aspect-ratio: 16 / 9;
        max-width: 100%;
        margin: 10px 0;
        border-radius: 8px;
        overflow: hidden;

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border: 0;
        }
      }
    }
  }

  &__comments-container {
    margin-top: 14px;
  }
}
