.report-step-modal {
  .report-step-modal-row {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
  .report-step-modal-answers {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .report-step-modal-category {
      font-size: 17px;
      font-family: "Campton-Bold";
      color: var(--blue);
    }
    .report-step-modal-answer {
      font-size: 15px;
      font-family: "Campton-Bold";
      line-height: 16px;
    }
  }
}
