@import "styles/_variables.scss";

.sidebar-cell {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  border-radius: 6px;
  padding: 7px;
  height: 40px;
  transition: 0.25s;
  cursor: pointer;
  &:hover:not(.active) {
    background-color: var(--background);
  }
  .sidebar-cell-icon-container {
    display: inline-block;
    flex: 0 0 26px;
    height: 26px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 4px;
    justify-content: center;
    .sidebar-cell-icon {
      font-size: 14px;
      color: var(--white);
    }
  }
  .sidebar-cell-title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-top: 2px;
    color: var(--text);
  }
  &.active {
    background-color: var(--blue);
    color: var(--card);
    .sidebar-cell-icon {
      color: var(--card);
    }
    .sidebar-cell-title {
      color: var(--white);
    }
  }
}
