.data-modal {
  .data-modal-body {
    @media (max-width: 600px) {
      gap: 10px;
      flex-direction: column-reverse;
    }
  }
  .data-modal-image {
    object-fit: contain;
  }
}
