.reaction {
  display: flex;
  align-items: center;

  .tippy-box {
    border-radius: 8px;
    .tippy-content {
      padding: 2px 4px;
    }
  }

  &__card {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--card);
    border-radius: 8px;
    padding: 6px 8px 2px 8px;
    margin-right: 8px;
    height: 32px;
    cursor: pointer;

    &--active {
      background-color: var(--blue);
      color: var(--white);
    }

    &--add {
      padding: 6px 8px;
    }

  }

  &__count {
    margin-left: 4px;
  }

  &__button {
    border: none;
    font-size: 1rem;
    margin-right: 8px;
    path {
      fill: var(--text);
    }
  }

  .reaction-options {
    display: flex;
    flex-wrap: nowrap;
    padding: 4px 0px 0px 0px;

    .reaction-option {
      padding: 1px 3px;
      cursor: pointer;
      border: none;
      background-color: transparent;
      font-size: 1.2rem;
    }
  }
}
