.report-submitted-sidebar {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: scroll;
  height: calc(100vh - 64px - 16px * 2 - 44px);
  @media (max-width: 575px) {
    height: auto;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  border-radius: 10px;
  &::-webkit-scrollbar {
    display: none;
  }
}
