.welcome-page {
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  gap: 20px;
  .ql-editor {
    min-height: 200px;
  }

  .ql-snow .ql-stroke {
    stroke: var(--text);
  }
  .ql-snow .ql-fill {
    fill: var(--text);
  }
  .ql-snow .ql-picker {
    color: var(--text);
  }
  .ql-snow .ql-picker-options {
    background-color: var(--card);
  }

  .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
    border-color: var(--border);
  }
  .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    border-color: var(--border);
    border-radius: 4px;
  }
  .ql-toolbar.ql-snow {
    border-width: 1px 1px 0px 1px;
    border-radius: 0.5em 0.5em 0px 0px;
    border-color: var(--border);
    background-color: var(--card);
  }

  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    border: 1px solid var(--border);
    border-radius: 0px 0px 0.5em 0.5em;
    background-color: var(--card);
  }
  .welcome-page-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    box-sizing: border-box;
  }
  .welcome-page-bar {
    display: flex;
    gap: 16px;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 8px;
    }

    .welcome-page-bar-progress-circular {
      align-content: center;
    }

    .welcome-page-bar-button-container {
      display: flex;
      flex-direction: row;
      gap: 8px;
    }

    .welcome-page-language-selector {
      flex: 1;
    }
  }
}
