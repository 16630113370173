.voice-cell {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--card);
  border-radius: 8px;
  padding: 8px 16px;
  cursor: pointer;
  gap: 10px;

  &__tag {
    white-space: nowrap;
    text-transform: capitalize;
  }

  &__tags {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
  
  &__status-tag {
    background-color: var(--background);
    padding: 4px 8px 2px 8px;
  }

  &__left-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    align-self: stretch;
    gap: 5px;
    h4 {
      font-size: 16px;
      font-family: "Campton-Medium";
    }
  }

  &__right-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    @media (max-width: 576px) {
      flex-direction: column;
    }
    gap: 10px;
    .company-logo {
      align-self: flex-end;
    }
  }

  &__details {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    @media (max-width: 576px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__date-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }

  &__header-date {
    font-size: 14px;
    color: var(--subtitle);
    font-family: "Campton-Medium";
    min-width: 85px;
  }

  &__user {
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
    gap: 8px;
    color: var(--subtitle);

    svg {
      width: 18px;
      height: 18px;
    }

    &-name {
      font-size: 14px;
      font-family: "Campton-Medium";
      overflow-wrap: break-word;
      word-wrap: break-word;
      hyphens: auto;
      @media (max-width: 576px) {
        max-width: 120px;
      }
    }

    &-anonymous {
      @media (max-width: 576px) {
        display: none;
      }
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
