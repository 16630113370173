@keyframes pulsate {
  0% {
    background-color: var(--card);
  }

  50% {
    background-color: var(--background);
  }

  100% {
    background-color: var(--card);
  }
}

.notme-news-cell {
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: hidden;

  // Styling for the image wrapper
  &__image-wrapper {
    position: relative;
    width: 100%;
    aspect-ratio: 3 / 2; // 3:2 aspect ratio
    border-radius: 8px;
    overflow: hidden;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex: 1;
    overflow: hidden;
  }

  &__content-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    font-family: "Campton-Medium";
    font-size: 16px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    overflow-wrap: break-word;
    word-wrap: break-word;
    text-overflow: ellipsis;
    &-read {
      color: var(--subtitle);
    }
  }

  &__tag-container {
    display: flex;
    gap: 8px;
    align-items: center;
    flex: 1;
  }

  &__tag {
    width: min-content;
    text-transform: capitalize;
  }

  &__date {
    font-size: 14px;
    color: var(--subtitle);
    text-align: right;
  }

  &--clickable {
    cursor: pointer;
  }

  &--vertical {
    flex-direction: row;
  }

  &--vertical &__image-wrapper {
    flex: 0 0 40%;
    height: auto;
    aspect-ratio: 1.15 / 1; // 1.15:1 aspect ratio
  }

  &--vertical &__image {
    position: static;
    height: 100%;
  }

  &--vertical &__title {
    font-size: 16px;
  }

  &--horizontal {
    scroll-snap-align: start;
    min-width: 200px;
  }

  &__skeleton {
    height: 1em;
    width: 100%;
    border-radius: 5px;
    background-color: var(--card);
    animation: pulsate 2s ease-in-out infinite;
  }

  &__skeleton--tag {
    width: 55px;
    height: 28px;
    margin-bottom: 10px;
  }

  &__skeleton--title {
    height: 20px;
    margin-bottom: 20px;
  }
}
