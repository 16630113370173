.report-step-dates {
  display: flex;
  flex-direction: column;
  gap: 16px;
  .react-date-picker__inputGroup {
    display: none;
  }
  .react-date-picker__button:enabled {
    width: 100%;
    padding: 9px 12px 9px 12px;
  }
  .react-date-picker__wrapper {
    border: none;
    border-width: 0px;
  }
  .react-date-picker {
    -webkit-appearance: none;
    outline: none;
    border: 1px solid var(--dark_grey);
    border-radius: 6px;
    background-color: var(--card);
  }
  .date-picker-placeholder {
    text-align: left;
    color: hsl(0, 0%, 50%);
  }
  .dates-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .dates-list {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .date-cell {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}
