.notme-loading {
  align-self: center;
  text-align: center;
}

.notme-loading-full {
  width: 100%;
}

@mixin notme-loading-theme($color) {
  color: var($color);
}

.notme-loading-text {
  @include notme-loading-theme(--text);
}
.notme-loading-subtitle {
  @include notme-loading-theme(--subtitle);
}
.notme-loading-dark_red {
  @include notme-loading-theme(--dark_red);
}
.notme-loading-dark_blue {
  @include notme-loading-theme(--dark_blue);
}
.notme-loading-red {
  @include notme-loading-theme(--red);
}
.notme-loading-dark_green {
  @include notme-loading-theme(--dark_green);
}
.notme-loading-green {
  @include notme-loading-theme(--green);
}
.notme-loading-light_blue {
  @include notme-loading-theme(--light_blue);
}
.notme-loading-blue {
  @include notme-loading-theme(--blue);
}
.notme-loading-dark_grey {
  @include notme-loading-theme(--dark_grey);
}
.notme-loading-yellow {
  @include notme-loading-theme(--yellow);
}
.notme-loading-light_red {
  @include notme-loading-theme(--light_red);
}
