@keyframes pulsate {
  0% {
    background-color: var(--card);
  }

  50% {
    background-color: var(--background);
  }

  100% {
    background-color: var(--card);
  }
}

.image-loading__wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: var(--card);

  &--loading {
    animation: pulsate 2s ease-in-out infinite;
  }
}

.image-loading__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;

  &--loaded {
    opacity: 1;
  }

  &--error {
    opacity: 0;
  }
}

.image-loading__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  color: var(--subtitle);

  &--show {
    opacity: 1;
  }
}
