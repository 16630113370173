.posts-page {
  margin-top: 10px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  padding-bottom: 25px;
  &__grid {
    display: grid;
    grid-template-columns: 1fr;

    @media (min-width: 576px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 768px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: 1200px) {
      grid-template-columns: repeat(4, 1fr);
    }

    gap: 20px;
    .notme-news-cell {
      min-width: 0;
    }
    > * {
      justify-self: center;
      width: 100%;
    }
  }
}
