.report-step-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--background);
  width: 40px;
  height: 40px;
  border-radius: 4px;
  .report-step-icon {
    font-size: 14px;
  }
}
