.entity-select-tag-container {
  display: inline-block;
  .entity-select-tag {
    display: flex;
    flex-direction: row;
    gap: 4px;
    background-color: var(--card);
    border: 1px solid var(--border);
    border-radius: 6px;
    align-items: center;
    padding: 0px 4px;
    margin: 0px 8px 6px 0px;
    p {
      padding-top: 4px;
      font-size: 13px;
    }
    .entity-select-tag-img {
      object-fit: contain;
      border-radius: 4px;
      background-color: var(--white);
    }
    .entity-select-tag-btn {
      height: 16px;
      width: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: 0.25s;
      border-radius: 4px;
      color: var(--subtitle);
      &:hover {
        color: var(--white);
        background-color: var(--subtitle);
        opacity: 0.3;
      }
    }
  }
}
