.carousel-arrow {
  @mixin arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: var(--blue);
    color: var(--white);
    height: 28px;
    width: 28px;
    border-radius: 6px;
    font-size: 12px;
    top: 18px;
    margin: 0 4px;
    cursor: pointer;
    z-index: 20;
  }

  &-right {
    @include arrow();
    right: 0;
  }

  &-left {
    @include arrow();
    left: 0;
  }

  &-disabled {
    background-color: var(--dark_grey) !important;
    cursor: default;
  }

  &-light_blue {
    background-color: var(--light_blue);
  }

  &-blue {
    background-color: var(--blue);
  }

  &-green {
    background-color: var(--green);
  }

  &-red {
    background-color: var(--red);
  }

  &-dark_blue {
    background-color: var(--dark_blue);
  }
}
