.report-chat {
  height: calc(100vh - 64px - 16px * 2 - 44px);
  background-color: var(--card);
  padding: 0px;
  position: relative;
  .report-chat-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: var(--card);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    // height: 64px;
    padding: 10px 10px 4px 10px;
    border-bottom: 1px solid var(--background);
    .report-chat-header-title {
      font-family: "Campton-Medium";
      font-size: 24px;
    }
  }
  .report-chat-content {
    overflow-y: scroll;
    height: 100%;
    position: relative;
    background-color: var(--card);
    flex: 1 1 0%;
    height: calc(100vh - 64px - 16px * 2 - 44px - 64px * 2 - 8px);

    .report-chat-loading {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: var(--grey);
      padding: 10px;
    }

    .report-chat-banner {
      text-align: center;
      margin: 20px;
      font-size: 14px;
      border-radius: 8px;
      background-color: var(--background);
      padding: 4px 4px 0px 4px;
      .report-chat-banner-text {
        font-size: 14px;
      }
    }

    .report-chat-messages-list {
      list-style: none;
      -webkit-padding-start: 0px;
      padding: 10px;
    }
  }
  .report-chat-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    min-height: 56px;
    max-height: 200px;
    background-color: var(--card);
    padding: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 10px;
    padding: 8px 8px 0px 8px;
    .report-chat-footer-textarea {
      flex: 1;
    }
    .report-chat-footer-send-button {
      height: 38px;
      margin-bottom: 8px;
    }
  }
}
