.comment-input {
  display: flex;
  align-items: flex-start;
  position: fixed;
  bottom: 0;
  gap: 10px;
  z-index: 2;

  max-width: 744px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 768px) {
    padding: 0 12px;
    left: 0;
  }

  @media screen and (min-width: 769px) and (max-width: 1024px) {
    max-width: calc(100% - 255px - 24px); // Subtract sidebar width and adjust for padding
  }

  &__avatar {
    width: 44px;
    height: 44px;
  }

  &__comment-area {
    flex-grow: 1;
    textarea {
      padding-right: 48px !important;
    }
    overflow: hidden;
    .notme-text-area--counter {
      padding-bottom: 4px;
      margin-top: -4px;
    }
  }

  &__submit-button {
    align-self: center;
    position: absolute;
    right: 6px;
    bottom: 36px;
    @media screen and (max-width: 768px) {
      right: 18px;
    }
  }

  &::before {
    content: "";
    height: 116px;
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    background-color: var(--card);
    z-index: -1;
    -webkit-box-shadow: var(--shadow);
    -moz-box-shadow: var(--shadow);
    box-shadow: var(--shadow);
  }
}
