.profile-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  .profile-item-title {
    font-size: 16px;
  }
  .profile-item-value {
    color: var(--grey);
  }
}
