.setting-section {
  display: flex;
  flex-direction: row;
  @media (max-width: 600px) {
    flex-direction: column;
  }
  .setting-section-title-container {
    flex: 0 0 180px;
    padding-right: 10px;
    @media (max-width: 600px) {
      flex-basis: 40px;
      padding: 0px 0px 10px 0px;
    }

    .setting-section-title {
      font-size: 16px;
      font-weight: 700;
    }

    .setting-section-description {
      font-size: 14px;
      font-family: "Campton-Medium";
      color: var(--subtitle);
    }

    @media (max-width: 600px) {
      width: 100%;
    }
  }
  .setting-section-children {
    flex-grow: 1;
    overflow: hidden;
  }
}
