.notme-dropdown-sort {
  display: inline-flex;
  gap: 8px;

  .notme-dropdown-sort--button {
    color: var(--text);
    background-color: var(--card);
    border: var(--text);
    padding: 8px 12px;
    &:hover {
      background-color: var(--lighter_grey);
    }
  }
}
