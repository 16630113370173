.section-tab {
  cursor: pointer;
  &__tag {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px 12px 6px 6px;
    gap: 12px;
  }
}
