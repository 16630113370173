.permissions-page {
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  gap: 20px;

  .permissions-page-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 576px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }

    .permissions-page-header-text {
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-items: center;
    }

    .permissions-page-header-buttons {
      display: flex;
      flex-direction: row;
      gap: 16px;
      align-items: center;
    }
  }
}
