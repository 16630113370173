.notme-input-search {
  display: block !important;

  .notme-input-search--selected-item {
    display: flex;
    align-items: center;
    padding: 0px 6px 3px 6px;
    border-radius: 4px;
    border: 1px solid var(--border);
    gap: 6px;
    .notme-input-search--selected-item-text {
      padding-top: 2px;
      font-size: 14px;
    }
    .notme-input-search--selected-item-icon {
      cursor: pointer;
      color: var(--grey);
      &:hover {
        color: var(--border);
      }
    }
  }

  .notme-input-search--option-container {
    cursor: default;

    .notme-input-search--option-container-text {
      font-size: 15px;
      padding: 4px 0px 4px 12px;
    }

    .notme-input-search--option-container-list {
      list-style: none;
      -webkit-padding-start: 0px;
      margin: 0px;
      overflow-y: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
      .notme-input-search--option {
        cursor: pointer;
        margin: 0px 4px;
        border-radius: 4px;
        padding: 6px 8px 6px 8px;
        &:hover {
          background-color: var(--lighter_grey);
        }
      }
      max-height: 300px;
      overflow-y: scroll;
    }
  }
}
