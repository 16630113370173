.post-youtube {
  position: relative;
  .youtube-iframe {
    aspect-ratio: 16/9;
    background: var(--card);
    border-radius: 8px;
  }
  .delete-node-button {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
  }
}
