.document-dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 10px;
  border-width: 2px;
  border-radius: 8px;
  border-color: var(--border);
  border-style: dashed;
  background-color: var(--card);
  color: var(--subtitle);
  outline: none;
  transition: border 0.2s ease-in-out;
  cursor: pointer;
  gap: 10px;
  &:focus {
    border-color: var(--light_blue);
  }
  &-text {
    color: var(--subtitle);
  }

  .document-dropzone-indicator {
    text-align: center;
    .document-size-text {
      font-size: 15px;
    }
  }
}
.document-dropzone-image {
  border-width: 2px;
  border-radius: 8px;
  border-color: var(--light_blue);
  border-style: double;
  max-height: 160px;
  object-fit: cover;
}

.document-cell {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--card);
  border-radius: 6px;
  padding: 8px;
  .document-cell-body {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    .document-cell-title {
      font-family: "Campton-Bold";
      font-size: 15px;
      word-break: break-all;
    }
  }
}
