.notme-button {
  display: inline-block;
  background: var(--blue);
  border-radius: 6px;
  align-items: center;
  cursor: pointer;
  transition: 0.25s;
  border: 1px solid var(--blue);
  color: var(--white);
  padding: 8px 14px;
  font-family: "Campton-Medium";
  font-size: 15px;

  .icon-spacing {
    display: inline;
    margin-right: 10px;
  }

  &:hover:enabled {
    opacity: 0.9;
  }

  &:active:enabled {
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
}

.notme-button-full {
  display: block;
  width: 100%;
}

@mixin notme-button-theme($color) {
  border: 1px solid var($color);
  background: var($color);
}

@mixin notme-button-theme-outline($color) {
  background: none;
  color: var($color);
  border: 1px solid var($color);
}

@mixin notme-button-theme-plain($color) {
  background: none;
  color: var($color);
  border: none;
  margin: 1px;
}

.notme-button-blue {
  @include notme-button-theme(--blue);
}
.notme-button-dark_blue {
  @include notme-button-theme(--dark_blue);
}
.notme-button-light_blue {
  @include notme-button-theme(--light_blue);
}
.notme-button-red {
  @include notme-button-theme(--red);
}
.notme-button-dark_red {
  @include notme-button-theme(--dark_red);
}
.notme-button-light_red {
  @include notme-button-theme(--light_red);
}
.notme-button-green {
  @include notme-button-theme(--green);
}
.notme-button-dark_green {
  @include notme-button-theme(--dark_green);
}
.notme-button-yellow {
  @include notme-button-theme(--yellow);
}
.notme-button-grey {
  @include notme-button-theme(--grey);
}
.notme-button-dark_grey {
  @include notme-button-theme(--dark_grey);
}
.notme-button-light_grey {
  @include notme-button-theme(--light_grey);
}

.notme-button-blue-outline {
  @include notme-button-theme-outline(--blue);
}
.notme-button-dark_blue-outline {
  @include notme-button-theme-outline(--dark_blue);
}
.notme-button-light_blue-outline {
  @include notme-button-theme-outline(--light_blue);
}
.notme-button-red-outline {
  @include notme-button-theme-outline(--red);
}
.notme-button-dark_red-outline {
  @include notme-button-theme-outline(--dark_red);
}
.notme-button-light_red-outline {
  @include notme-button-theme-outline(--light_red);
}
.notme-button-green-outline {
  @include notme-button-theme-outline(--green);
}
.notme-button-dark_green-outline {
  @include notme-button-theme-outline(--dark_green);
}
.notme-button-yellow-outline {
  @include notme-button-theme-outline(--yellow);
}
.notme-button-grey-outline {
  @include notme-button-theme-outline(--grey);
}
.notme-button-dark_grey-outline {
  @include notme-button-theme-outline(--dark_grey);
}
.notme-button-light_grey-outline {
  @include notme-button-theme-outline(--light_grey);
}

.notme-button-blue-plain {
  @include notme-button-theme-plain(--blue);
}
.notme-button-dark_blue-plain {
  @include notme-button-theme-plain(--dark_blue);
}
.notme-button-light_blue-plain {
  @include notme-button-theme-plain(--light_blue);
}
.notme-button-red-plain {
  @include notme-button-theme-plain(--red);
}
.notme-button-dark_red-plain {
  @include notme-button-theme-plain(--dark_red);
}
.notme-button-light_red-plain {
  @include notme-button-theme-plain(--light_red);
}
.notme-button-green-plain {
  @include notme-button-theme-plain(--green);
}
.notme-button-dark_green-plain {
  @include notme-button-theme-plain(--dark_green);
}
.notme-button-yellow-plain {
  @include notme-button-theme-plain(--yellow);
}
.notme-button-grey-plain {
  @include notme-button-theme-plain(--grey);
}
.notme-button-dark_grey-plain {
  @include notme-button-theme-plain(--dark_grey);
}
.notme-button-light_grey-plain {
  @include notme-button-theme-plain(--light_grey);
}

.is-loading {
  pointer-events: none;
  opacity: 0.6;
  cursor: not-allowed;
}
