.main-page {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .greeting-header {
  }
  .main-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .reports-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .reports-loading {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: var(--grey);
      padding-top: 20px;
    }
  }
}
