.report-page {
  .report-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    @media (max-width: 575px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }
  }
  .report-buttons {
    display: flex;
    gap: 10px;
    .delete-report-button {
      margin-right: 10px;
    }
  }
  .report-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .report-row {
      @media (max-width: 575px) {
        gap: 10px;
      }
    }
  }
  .report-col {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .report-loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--grey);
    padding-top: 100px;
  }
  .report-submitted {
    height: calc(100vh - 64px - 16px * 2 - 44px);
    overflow-y: hidden;
    overflow-x: hidden;

    @media (max-width: 575px) {
      display: none;
    }
  }
}
