.chat-bubble {
  display: inline-block;
  clear: both;
  font-size: 14px;
  padding: 6px 10px 4px 10px;
  border-radius: 8px;
  margin-bottom: 2px;
  max-width: 220pt;
}

.subscriber {
  float: left;
  text-align: left;
  background: var(--background);
  color: var(--text);
}

.not-subscriber {
  float: right;
  text-align: right;
  background: var(--blue);
  color: var(--white);
}

// li {
//     display: inline-block;
//     clear: both;
//     padding: 10px;
//     border-radius: 25px;
//     margin-bottom: 4px;
//     max-width: 220pt;

//     .text-content {
//       margin: 0px;
//     }

//     .date {
//       margin: 0px;
//       font-size: 12px;
//     }
//   }

// .info {
//   text-align: center;
//   background: #eee;
//   color: $notme-red;
//   max-width: 100% !important;
//   width: 100% !important;
//   margin: 10px 0px 10px 0px;
//   font-size: 14px;
//   border-radius: 10px;
// }
