.permission-edit-modal {
  @media (min-width: 576px) {
    width: 468px;
  }
  .permission-edit-modal-container {
    flex-direction: column;
    gap: 8px;
    justify-content: space-between;
    align-items: normal;
  }

  .permission-edit-indications {
    font-size: 15px;
    .permission-edit-indications-link {
      color: var(--blue);
      text-decoration: none;
      font-weight: 500;
    }
  }
}
