.permission-member-cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  .permission-member-cell-info-left {
    display: flex;
    flex-direction: row;
    flex: 0 0 auto;
    width: 60%;
    gap: 12px;
    @media (max-width: 600px) {
      width: calc(100% - 104px);
      align-items: center;
    }
    .permission-member-cell-avatar {
      flex: 0 0 44px;
    }

    .permission-member-cell-name-role-container {
      flex: 0 0 auto;
      width: calc(100% - 54px);
      display: flex;
      flex-direction: row;
      gap: 6px;
      @media (max-width: 600px) {
        flex-direction: column;
      }
      .permission-member-cell-name {
        width: 65%;
        @media (max-width: 600px) {
          display: flex;
          flex-direction: column;
          width: 100%;
        }
        .permission-member-cell-name-text {
          font-size: 16px;
          font-family: "Campton-Bold";
        }
        .permission-member-cell-title-text {
          font-size: 15px;
          font-family: "Campton-Medium";
          color: var(--subtitle);
        }
      }
      .permission-member-cell-role-container {
        flex: 0 0 auto;
        width: 35%;
        align-content: center;
        @media (max-width: 600px) {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 4px;
          width: 100%;
        }
      }
    }
  }
  .permission-member-cell-date-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 0 0 auto;
    width: calc(40% - 104px);
    gap: 12px;
    justify-content: space-between;
    @media (max-width: 600px) {
      width: 0px;
      display: none;
    }
  }
  .permission-member-cell-date-text {
    font-size: 15px;
    font-family: "Campton-Medium";
    color: var(--subtitle);
  }
  .permission-member-cell-date-text-small {
    display: none;
    @media (max-width: 600px) {
      display: block;
    }
  }
  .permission-member-cell-button-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    flex: 0 0 calc(26px * 2 + 36px + 8px * 2);
  }
}
