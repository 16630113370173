.notme-avatar {
  display: inline-block;
  font-weight: 400;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  white-space: nowrap;
  vertical-align: middle;
  padding: 0;
  text-align: center;

  .notme-avatar-text {
    position: relative;
    top: 25%;
    color: var(--white);
    font-weight: 800;
    font-size: 16px;
    margin: 0 auto;
  }

  .notme-avatar-count {
    margin-top: 4px;
    margin-right: -6px;
    display: flex;
    justify-content: flex-end;
    .notme-avatar-count-text-container {
      background-color: var(--subtitle);
      border-radius: 4px;
      border: 1px solid var(--white);
      .notme-avatar-count-text {
        margin-bottom: 0px;
        font-size: 12px;
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 2px;
        color: var(--white);
        font-family: "Campton-Medium";
      }
    }
  }
}

.notme-avatar-round {
  border-radius: 8px;
}

@mixin notme-avatar-theme($color) {
  background-color: var($color);
}

.notme-avatar-dark_red {
  @include notme-avatar-theme(--dark_red);
}
.notme-avatar-dark_blue {
  @include notme-avatar-theme(--dark_blue);
}
.notme-avatar-red {
  @include notme-avatar-theme(--red);
}
.notme-avatar-dark_green {
  @include notme-avatar-theme(--dark_green);
}
.notme-avatar-green {
  @include notme-avatar-theme(--green);
}
.notme-avatar-light_blue {
  @include notme-avatar-theme(--light_blue);
}
.notme-avatar-blue {
  @include notme-avatar-theme(--blue);
}
.notme-avatar-dark_grey {
  @include notme-avatar-theme(--dark_grey);
}
.notme-avatar-yellow {
  @include notme-avatar-theme(--yellow);
}
.notme-avatar-light_red {
  @include notme-avatar-theme(--light_red);
}
