.notme-news-top-section {
  display: flex;
  flex-direction: row;
  gap: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  &__item {
    &--featured {
      width: 55%;
      @media (max-width: 768px) {
        width: 100%;
      }
      &.full-width {
        width: 100%;
        @media (min-width: 769px) {
          .notme-news-cell__image-wrapper {
            height: 274.93px; // 55% of 768px - 10px of gap (for container width) * 2/3
          }
        }
      }
    }

    &--right {
      @media (max-width: 768px) {
        width: 100%;
      }
      width: 45%;
    }
  }
}
