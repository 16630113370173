@import "styles/_variables.scss";

.entity-selector-dropdown {
  position: absolute;
  width: 240px;
  display: flex;
  flex-direction: column;
  background-color: var(--card);
  border-radius: 8px;
  transition: transform 0.2s ease, opacity 0.2s ease, visibility 0s linear 0.2s;
  will-change: transform;
  z-index: 10;
  left: $sidebar-size;
  top: 10px;
  padding: 10px;
  max-height: 320px;

  border: 1px solid var(--border);
  box-shadow: var(--shadow);
  overflow: hidden;

  opacity: 0;
  visibility: hidden;

  .entity-selector-dropdown-input-container {
    border: 1px solid var(--dark_grey);
    display: flex;
    flex-direction: row;
    gap: 6px;
    border-radius: 6px;
    padding-left: 10px;
    // padding: 8px 10px 4px 10px;
    font-size: 16px;
    align-items: center;
    margin-bottom: 10px;
    .entity-selector-dropdown-icon {
      color: var(--subtitle);
    }
    .entity-selector-dropdown-input {
      border: none;
      outline: none;
      -webkit-appearance: none;
      background: transparent;
      padding: 8px 0px 6px 0px;
      color: var(--text);
    }
  }
  .entity-selector-dropdown-empty-container {
    padding: 20px 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 8px;
    .entity-selector-dropdown-empty-icon-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      height: 32px;
      width: 32px;
      background-color: var(--blue-a10);
      .entity-selector-dropdown-empty-icon {
        font-size: 16px;
        color: var(--blue);
      }
    }
    .entity-selector-dropdown-empty-text {
      word-break: break-word;
      .entity-selector-dropdown-empty-subtitle {
        color: var(--subtitle);
        font-size: 14px;
      }
    }
  }
}

.entity-selector-dropdown.visible {
  transform: translateX(10px);
  transition: transform 0.2s ease, opacity 0.2s ease, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}
.entity-selector-dropdown-reduced {
  left: $sidebar-size-reduced;
}

.entity-selector-dropdown-mobile {
  width: auto;
  right: 20px;
  left: 0px;
  top: auto;
  box-shadow: none;
}
