.report-header {
  .report-header-info-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .report-header-info {
      display: flex;
      flex-direction: column;
      .report-header-info-row {
        display: flex;
        flex-direction: row;
        gap: 14px;
        align-items: center;

        .report-header-info-row-icon {
          font-size: 12px;
        }

        .report-header-info-row-text {
          font-size: 14px;
        }
      }
    }
  }
}
