.report-evidence {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .report-evidence-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .report-evidence-uploader {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .report-evidence-uploader-list {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
    .report-evidence-uploader-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .report-evidence-uploader-body {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 16px;
        .report-evidence-uploader-row-title {
          font-family: "Campton-Bold";
          font-size: 15px;
          word-break: break-all;
        }
      }
    }
    .report-evidence-uploader-status {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;

      .report-evidence-uploader-icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--green);
        width: 40px;
        height: 40px;
        border-radius: 4px;
        color: var(--white);
      }
      .report-evidence-uploader-title {
        font-family: "Campton-Bold";
        font-size: 15px;
      }
    }
    .button-container {
      display: flex;
      gap: 10px;
      flex-direction: row-reverse;
    }
  }
}
