.report-evidence-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.evidence-modal {
  max-width: 400px;
  width: 100%;
  height: 100%;
  overflow: auto;
}
.evidence-body {
  overflow-y: scroll;
  height: auto;
  max-height: calc(100vh - 100px);
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
.evidence-img {
  max-width: 100%;
  height: auto;
}
