.entity-list {
  list-style: none;
  -webkit-padding-start: 0px;
  margin: 0px;
  overflow-y: scroll;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
