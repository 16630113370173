.report-questionnaire {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .report-questionnaire-title {
    display: flex;
    flex-direction: row;
    height: 40.5px;
    justify-content: space-between;
    align-items: center;
  }
  .report-questionnaire-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .report-questionnaire-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1px;
    cursor: pointer;
    .report-questionnaire-row-content {
      display: flex;
      flex-direction: row;
      gap: 14px;
      align-items: center;
      .report-questionnaire-row-text {
        font-size: 14px;
        font-family: "Campton-Bold";
      }
    }
  }
}
