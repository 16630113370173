.react-calendar {
  width: 350px;
  margin-top: 8px;
  max-width: 100%;
  padding: 2px;
  background: var(--card);
  border: 1px solid var(--dark_grey);
  border-radius: 10px;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;

  &--doubleView {
    width: 700px;

    .react-calendar__viewContainer {
      display: flex;
      margin: -0.5em;

      > * {
        width: 50%;
        margin: 0.5em;
      }
    }
  }

  &,
  & *,
  & *:before,
  & *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  button {
    margin: 0;
    border: 0;
    outline: none;

    &:disabled {
      color: var(--text);
      background-color: var(--lighter_grey);
    }

    &:enabled {
      &:hover {
        cursor: pointer;
      }
    }
  }

  &__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 1em;

    button {
      min-width: 44px;
      background: none;
      color: var(--text);

      &:disabled {
        background-color: var(--lighter_grey);
      }

      &:enabled {
        &:hover,
        &:focus {
          background-color: var(--lighter_grey);
        }
      }
    }
  }

  &__month-view {
    &__weekdays {
      text-align: center;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.75em;
      color: var(--text);

      .react-calendar__month-view__weekdays__weekday {
        abbr {
          text-decoration: none;
        }
      }

      &__weekday {
        padding: 0.5em;
      }
    }

    &__weekNumbers {
      .react-calendar__tile {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.75em;
        font-weight: bold;
      }
    }

    &__days {
      &__day {
        color: var(--text);
        &--weekend {
          color: var(--red);
        }

        &--neighboringMonth {
          color: var(--dark_grey);
        }
      }
    }
  }

  &__year-view,
  &__decade-view,
  &__century-view {
    .react-calendar__tile {
      color: var(--text);
      padding: 2em 0.5em;

      &--active {
        color: var(--white);
      }
    }
  }

  &__tile {
    max-width: 100%;
    padding: 10px 6.6667px;
    background: none;
    text-align: center;
    line-height: 16px;

    &:disabled {
      background-color: var(--lighter_grey);
    }

    &:enabled {
      &:hover,
      &:focus {
        background-color: var(--lighter_grey);
      }
    }

    &--now {
      background: var(--light_blue);
      color: var(--white);

      &:enabled {
        &:hover,
        &:focus {
          opacity: 0.6;
          color: var(--text);
        }
      }
    }

    &--hasActive {
      $bgcolor: lighten(rgb(0, 110, 220), 30%);
      background: $bgcolor;

      &:enabled {
        &:hover,
        &:focus {
          background: lighten($bgcolor, 10%);
        }
      }
    }

    &--active {
      $bgcolor: rgb(0, 110, 220);
      background: $bgcolor;
      color: var(--white);

      &:enabled {
        &:hover,
        &:focus {
          background: lighten($bgcolor, 10%);
        }
      }
    }
  }

  &--selectRange {
    .react-calendar__tile {
      &--hover {
        background-color: var(--lighter_grey);
      }
    }
  }
}
