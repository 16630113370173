.entity-list-cell {
  display: flex;
  flex-direction: row;
  padding: 5px 0px;
  .entity-list-cell-checkbox {
    padding-top: 0px;
    padding-bottom: 0px;
    width: 100%;
    margin-bottom: 0px;
    margin-right: 0px;
    padding-left: 28px;
    .entity-list-cell-checkbox--checkbox {
      top: 7px;
      border-radius: 5px;
    }
  }

  .notme-checkbox:hover
    .notme-checkbox--input:not(.notme-checkbox--input:checked)
    ~ .notme-checkbox--checkmark {
    background-color: var(--background);
  }
  .entity-list-cell-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    .entity-list-cell-img {
      object-fit: contain;
      border-radius: 6px;
      background-color: var(--white);
    }
    .entity-list-cell-title {
    }
  }
}
