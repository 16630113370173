.notme-dropdown-container {
  position: relative;
  .notme-dropdown-container--content {
    position: relative;
    display: inline-flex;
    .notme-dropdown-container--options {
      position: absolute;
      top: calc(100% + 8px);
      z-index: 8;
      margin: 0;
      padding: 0;
      right: 0;
      list-style-type: none;
      border-radius: 6px;
      background-color: var(--card);
      -webkit-box-shadow: var(--shadow);
      -moz-box-shadow: var(--shadow);
      box-shadow: var(--shadow);
      animation: fadeIn 0.15s ease-in-out;
      padding: 4px 0px;
      cursor: pointer;

      @media screen and (max-width: 576px) {
        right: auto;
        left: 0;
      }
    }

    .notme-dropdown-container--options-left {
      right: auto;
      left: 0;
    }

    .notme-dropdown-container--options-full {
      width: 100%;
    }

    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: translateY(-28px) scale(0.85);
      }
      to {
        opacity: 1;
        transform: translateY(0) scale(1);
      }
    }
  }
}
