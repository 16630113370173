.news-feed-page {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 1rem 0;

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .news-feed {
    .news-feed__shelves-container {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
}
