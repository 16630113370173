.notme-tag-select-item {
  display: inline-block;
  border: 1px solid var(--blue);
  color: var(--blue);
  padding: 4px 10px 2px 10px;
  font-size: 14px;
  transition: 0.1s;
  font-family: "Campton-Medium";
  margin: 5px 10px 5px 0px;
  border-radius: 8px;
  text-align: left;
  box-sizing: border-box;
  cursor: pointer;
}

.notme-tag-select-item-selected {
  color: white;
  background-color: var(--blue);
  border: 1px solid var(--blue);
}

.notme-tag-select-icon {
  background-color: var(--card);
  border-color: var(--card);
  color: var(--text);
  svg {
    margin-right: 6px;
  }
}

.notme-tag-select-icon-selected {
  color: var(--card);
}

@mixin notme-tag-select-item-theme($color) {
  border: 1px solid var($color);
  color: var($color);
}

@mixin notme-tag-select-item-selected-theme($color) {
  color: white;
  background-color: var($color);
  border: 1px solid var($color);
}

.notme-tag-select-item-blue {
  @include notme-tag-select-item-theme(--blue);
}
.notme-tag-select-item-dark_blue {
  @include notme-tag-select-item-theme(--dark_blue);
}
.notme-tag-select-item-light_blue {
  @include notme-tag-select-item-theme(--light_blue);
}
.notme-tag-select-item-red {
  @include notme-tag-select-item-theme(--red);
}
.notme-tag-select-item-dark_red {
  @include notme-tag-select-item-theme(--dark_red);
}
.notme-tag-select-item-light_red {
  @include notme-tag-select-item-theme(--light_red);
}
.notme-tag-select-item-green {
  @include notme-tag-select-item-theme(--green);
}
.notme-tag-select-item-dark_green {
  @include notme-tag-select-item-theme(--dark_green);
}
.notme-tag-select-item-yellow {
  @include notme-tag-select-item-theme(--yellow);
}
.notme-tag-select-item-grey {
  @include notme-tag-select-item-theme(--grey);
}
.notme-tag-select-item-dark_grey {
  @include notme-tag-select-item-theme(--dark_grey);
}
.notme-tag-select-item-light_grey {
  @include notme-tag-select-item-theme(--light_grey);
}

.notme-tag-select-item-selected-blue {
  @include notme-tag-select-item-selected-theme(--blue);
}
.notme-tag-select-item-selected-dark_blue {
  @include notme-tag-select-item-selected-theme(--dark_blue);
}
.notme-tag-select-item-selected-light_blue {
  @include notme-tag-select-item-selected-theme(--light_blue);
}
.notme-tag-select-item-selected-red {
  @include notme-tag-select-item-selected-theme(--red);
}
.notme-tag-select-item-selected-dark_red {
  @include notme-tag-select-item-selected-theme(--dark_red);
}
.notme-tag-select-item-selected-light_red {
  @include notme-tag-select-item-selected-theme(--light_red);
}
.notme-tag-select-item-selected-green {
  @include notme-tag-select-item-selected-theme(--green);
}
.notme-tag-select-item-selected-dark_green {
  @include notme-tag-select-item-selected-theme(--dark_green);
}
.notme-tag-select-item-selected-yellow {
  @include notme-tag-select-item-selected-theme(--yellow);
}
.notme-tag-select-item-selected-grey {
  @include notme-tag-select-item-selected-theme(--grey);
}
.notme-tag-select-item-selected-dark_grey {
  @include notme-tag-select-item-selected-theme(--dark_grey);
}
.notme-tag-select-item-selected-light_grey {
  @include notme-tag-select-item-selected-theme(--light_grey);
}
