.notme-password-checker-input {
  .password-score-indicator {
    display: flex;
    flex-direction: column;
    font-family: "Campton-Medium";
    font-size: 15px;
    text-align: right;
    margin-top: 4px;
  }
  .feedback-container {
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin: 10px 0px;
    background-color: var(--background);
    border-radius: 8px;
    padding: 14px;
  }
  .feedback-item {
    display: flex;
    flex-direction: column;
    gap: 4px;
    .feedback-title-label {
      font-family: "Campton-Medium";
      font-size: 16px;
      padding-bottom: 2px;
      color: var(--blue);
    }
    .feedback-label-icon {
      display: flex;
      gap: 8px;
      flex-direction: row;
      align-items: "flex-start";
      .feedback-icon {
        margin-top: 3px;
      }

      .feedback-label {
        font-family: "Campton-Medium";
        font-size: 15px;
        color: var(--text);
      }
    }
  }

  .progress {
    margin-top: 12px;
    background-color: var(--background);
    height: 6px;
  }
}
