.notme-tag {
  display: inline-block;
  border: 1px solid var(--blue);
  color: var(--blue);
  padding: 4px 10px 2px 10px;
  font-size: 14px;
  transition: 0.1s;
  font-family: "Campton-Medium";
}

.notme-tag-clickable {
  cursor: pointer;
}

.notme-tag-small {
  font-size: 12px;
  padding: 4px 8px 2px 8px;
  white-space: nowrap;
}

@mixin notme-tag-theme($color) {
  background: none;
  color: var($color);
  border: 1px solid var($color);
}

@mixin notme-tag-theme-solid($color) {
  background: var($color);
  color: var(--white);
  border: none;
}

@mixin notme-tag-theme-outline($color) {
  background: none;
  color: var($color);
  border: none;
}

@mixin notme-tag-theme-subtle($color, $background) {
  background: var($background);
  color: var($color);
  border: none;
}

@mixin notme-tag-theme-round($color) {
  background: none;
  color: var($color);
  border-radius: 6px;
  border: 1px solid var($color);
}

.notme-tag-blue {
  @include notme-tag-theme(--blue);
}
.notme-tag-dark_blue {
  @include notme-tag-theme(--dark_blue);
}
.notme-tag-light_blue {
  @include notme-tag-theme(--light_blue);
}
.notme-tag-red {
  @include notme-tag-theme(--red);
}
.notme-tag-dark_red {
  @include notme-tag-theme(--dark_red);
}
.notme-tag-light_red {
  @include notme-tag-theme(--light_red);
}
.notme-tag-green {
  @include notme-tag-theme(--green);
}
.notme-tag-dark_green {
  @include notme-tag-theme(--dark_green);
}
.notme-tag-yellow {
  @include notme-tag-theme(--yellow);
}
.notme-tag-grey {
  @include notme-tag-theme(--grey);
}
.notme-tag-dark_grey {
  @include notme-tag-theme(--dark_grey);
}
.notme-tag-light_grey {
  @include notme-tag-theme(--light_grey);
}
.notme-tag-card {
  @include notme-tag-theme(--card);
}

.notme-tag-blue-outline {
  @include notme-tag-theme-outline(--blue);
}
.notme-tag-dark_blue-outline {
  @include notme-tag-theme-outline(--dark_blue);
}
.notme-tag-light_blue-outline {
  @include notme-tag-theme-outline(--light_blue);
}
.notme-tag-red-outline {
  @include notme-tag-theme-outline(--red);
}
.notme-tag-dark_red-outline {
  @include notme-tag-theme-outline(--dark_red);
}
.notme-tag-light_red-outline {
  @include notme-tag-theme-outline(--light_red);
}
.notme-tag-green-outline {
  @include notme-tag-theme-outline(--green);
}
.notme-tag-dark_green-outline {
  @include notme-tag-theme-outline(--dark_green);
}
.notme-tag-yellow-outline {
  @include notme-tag-theme-outline(--yellow);
}
.notme-tag-grey-outline {
  @include notme-tag-theme-outline(--grey);
}
.notme-tag-dark_grey-outline {
  @include notme-tag-theme-outline(--dark_grey);
}
.notme-tag-light_grey-outline {
  @include notme-tag-theme-outline(--light_grey);
}
.notme-tag-card-outline {
  @include notme-tag-theme-outline(--card);
}

.notme-tag-blue-round {
  @include notme-tag-theme-round(--blue);
}
.notme-tag-dark_blue-round {
  @include notme-tag-theme-round(--dark_blue);
}
.notme-tag-light_blue-round {
  @include notme-tag-theme-round(--light_blue);
}
.notme-tag-red-round {
  @include notme-tag-theme-round(--red);
}
.notme-tag-dark_red-round {
  @include notme-tag-theme-round(--dark_red);
}
.notme-tag-light_red-round {
  @include notme-tag-theme-round(--light_red);
}
.notme-tag-green-round {
  @include notme-tag-theme-round(--green);
}
.notme-tag-dark_green-round {
  @include notme-tag-theme-round(--dark_green);
}
.notme-tag-yellow-round {
  @include notme-tag-theme-round(--yellow);
}
.notme-tag-grey-round {
  @include notme-tag-theme-round(--grey);
}
.notme-tag-dark_grey-round {
  @include notme-tag-theme-round(--dark_grey);
}
.notme-tag-light_grey-round {
  @include notme-tag-theme-round(--light_grey);
}
.notme-tag-card-round {
  @include notme-tag-theme-round(--card);
}

.notme-tag-blue-solid {
  @include notme-tag-theme-solid(--blue);
}
.notme-tag-dark_blue-solid {
  @include notme-tag-theme-solid(--dark_blue);
}
.notme-tag-light_blue-solid {
  @include notme-tag-theme-solid(--light_blue);
}
.notme-tag-red-solid {
  @include notme-tag-theme-solid(--red);
}
.notme-tag-dark_red-solid {
  @include notme-tag-theme-solid(--dark_red);
}
.notme-tag-light_red-solid {
  @include notme-tag-theme-solid(--light_red);
}
.notme-tag-green-solid {
  @include notme-tag-theme-solid(--green);
}
.notme-tag-dark_green-solid {
  @include notme-tag-theme-solid(--dark_green);
}
.notme-tag-yellow-solid {
  @include notme-tag-theme-solid(--yellow);
}
.notme-tag-grey-solid {
  @include notme-tag-theme-solid(--grey);
}
.notme-tag-dark_grey-solid {
  @include notme-tag-theme-solid(--dark_grey);
}
.notme-tag-light_grey-solid {
  @include notme-tag-theme-solid(--light_grey);
}
.notme-tag-card-solid {
  @include notme-tag-theme-solid(--card);
}

.notme-tag-blue-subtle {
  @include notme-tag-theme-subtle(--blue, --blue-a10);
}
.notme-tag-dark_blue-subtle {
  @include notme-tag-theme-subtle(--dark_blue, --dark_blue-a10);
}
.notme-tag-light_blue-subtle {
  @include notme-tag-theme-subtle(--light_blue, --light_blue-a10);
}
.notme-tag-red-subtle {
  @include notme-tag-theme-subtle(--red, --red-a10);
}
.notme-tag-dark_red-subtle {
  @include notme-tag-theme-subtle(--dark_red, --dark_red-a10);
}
.notme-tag-light_red-subtle {
  @include notme-tag-theme-subtle(--light_red, --light_red-a10);
}
.notme-tag-green-subtle {
  @include notme-tag-theme-subtle(--green, --green-a10);
}
.notme-tag-dark_green-subtle {
  @include notme-tag-theme-subtle(--dark_green, --dark_green-a10);
}
.notme-tag-yellow-subtle {
  @include notme-tag-theme-subtle(--yellow, --yellow-a10);
}
.notme-tag-grey-subtle {
  @include notme-tag-theme-subtle(--grey, --grey-a10);
}
.notme-tag-dark_grey-subtle {
  @include notme-tag-theme-subtle(--dark_grey, --dark_grey-a10);
}
.notme-tag-light_grey-subtle {
  @include notme-tag-theme-subtle(--light_grey, --light_grey-a10);
}
.notme-tag-card-subtle {
  @include notme-tag-theme-subtle(--subtitle, --card);
}
