.comment {
  display: flex;
  flex-direction: row;
  gap: 10px;
  background-color: var(--card);
  border-radius: 8px;
  padding: 8px;

  &:hover &__header-delete {
    opacity: 1;
  }

  &__avatar {
    width: 44px;
    height: 44px;
  }

  &__tag {
    font-size: 12px;
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2px;

    &-name {
      text-transform: capitalize;
      font-family: "Campton-Bold";
      display: flex;
      flex-direction: row;
      gap: 8px;
      &--viewer {
        color: var(--light_blue);
      }
    }

    &-right {
      display: flex;
      flex-direction: row;
      gap: 10px;
    }

    &-delete {
      padding: 0px;
      width: 20px;
      height: 20px;
      font-size: 16px;
      border-radius: 4px;
      border-color: var(--border);
      @media (min-width: 576px) {
        opacity: 0;
        transition: opacity 0.3s ease;
      }
    }

    &-date {
      color: var(--subtitle);
    }
  }

  &__content {
    flex: 1;
  }

  &__body {
    color: var(--text);
  }
}
