.floating-menu-editor {
  visibility: visible !important;
  display: flex;
  gap: 20px;
  left: -40px;
  position: relative;
  @media (max-width: 1400px) {
    left: 0;
  }
  .floating-menu-button {
    display: inline-block;
    background-color: var(--blue);
    border-radius: 4px;
    width: 26px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .floating-menu-icon {
      font-size: 12px;
      color: var(--white);
      transition: 0.2s;
    }
    .floating-menu-icon-rotate {
      transform: rotate(45deg);
    }
  }
  .floating-menu-buttons {
    display: flex;
    gap: 10px;
  }
}

.tippy-box[data-theme~="hidden"] {
  background: none;
}

.tippy-box[data-theme~="hidden"] > .tippy-content {
  padding: 0px;
}
