.notme-text-area {
  flex-direction: column;
  text-align: left;

  .notme-text-area--label {
    margin-bottom: 0.2em;
    font-family: "Campton-Medium";
    font-size: 16px;
    color: var(--text);
  }

  .notme-text-area--textarea {
    -webkit-appearance: none;
    outline: none;
    // border-radius: 0px;
    color: var(--text);
    border: 1px solid var(--dark_grey);
    border-radius: 6px;
    background-color: var(--card);
    padding: 12px 10px 10px 10px;
    width: 100%;
    font-size: 16px;
    font-family: "Campton-Medium";
    color: var(--text);
    &:focus {
      outline: none;
      border: 1px solid var(--blue);
    }
  }
  .notme-text-area--textarea-not-resizable {
    resize: none;
  }

  .notme-text-area--counter {
    text-align: right;
    font-size: 14px;
  }
  .notme-text-area--counter-limit {
    color: var(--red);
  }
  .notme-text-area--counter-limit-invisible {
    visibility: hidden;
  }
}
