.entity-cell {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--card);
  border-radius: 8px;
  padding: 8px 16px;
  gap: 10px;

  .entity-cell-logo-name-container {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;

    .entity-cell-name {
      font-family: "Campton-Medium";
      font-size: 16px;
    }
    .entity-cell-industry {
      font-family: "Campton-Medium";
      font-size: 14px;
      color: var(--subtitle);
      text-transform: capitalize;
    }
  }
  .entity-cell-status-button-container {
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
    .entity-cell-date {
      font-family: "Campton-Medium";
      font-size: 14px;
      color: var(--subtitle);
    }
  }
}
