.notme-news-section-horizontal {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 10px;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &-link {
      cursor: pointer;
    }

    &-link-text {
      color: var(--blue);
      font-size: 14px;
    }
  }

  &__container {
    display: flex;
    gap: 10px;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;

    // Hide scrollbar for Chrome, Safari and Opera
    &::-webkit-scrollbar {
      display: none;
    }

    // Hide scrollbar for IE, Edge and Firefox
    -ms-overflow-style: none;
    scrollbar-width: none;

    .notme-news-cell {
      &--horizontal {
        width: 200px;
      }
    }
  }

  &__buttons {
    position: absolute;
    top: 50%; // Position at the middle vertically
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    transform: translateY(
      -50%
    ); // To perfectly center vertically, offsetting its own height
  }
}
