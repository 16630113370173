.newsfeed-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__left {
    flex: 1;
  }

  &__right {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.newsfeed-loader {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.newsfeed-info-list {
  padding-top: 20px;
  list-style: none;
  -webkit-padding-start: 0px;
  margin: 0px;
  .newsfeed-info-item {
    span {
      font-weight: 700;
      color: var(--text);
    }
    p {
      color: var(--subtitle);
    }
  }
}

.newsfeed-title-input {
  border: none;
  display: flex;
  background-color: transparent;
  width: 100%;
  font-size: 26px;
  font-family: "Open Sans";
  font-weight: 600;
  color: var(--text);
  white-space: pre-wrap; /* Supports line breaks in text */
  overflow: hidden; /* Hide scrollbar */
  resize: none; /* Disable manual resize */
  &:focus {
    outline: none;
  }
}

.newsfeed-info-featured {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.newsfeed-info-cover {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-top: 20px;

  .newsfeed-info-cover-dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0px;
    border-width: 2px;
    border-radius: 8px;
    border-color: var(--border);
    border-style: dashed;
    background-color: var(--card);
    color: var(--subtitle);
    outline: none;
    transition: border 0.2s ease-in-out;
    cursor: pointer;
    gap: 10px;
    &:focus {
      border-color: var(--light_blue);
    }
    &-text {
      color: var(--subtitle);
    }
  }
  .newsfeed-info-cover-image {
    border-width: 2px;
    border-radius: 8px;
    border-color: var(--light_blue);
    border-style: double;
    max-height: 160px;
    object-fit: cover;
  }
}

.newsfeed-info {
  &__admin {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 20px;
    .entity-selector-dropdown {
      position: relative;
      left: 0px;
      top: 0px;
      transform: none !important;
      width: 100%;
    }
  }

  &__admin-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: nowrap;
    p {
      flex: 1;
    }
  }
}
