.report-anonymously {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 11px;
  .report-anonymously-body {
    display: flex;
    flex-direction: row;
    gap: 14px;
    align-items: center;
    .report-anonymously-body-text {
      font-size: 14px;
      font-family: "Campton-Bold";
    }
  }
  .checkbox-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    border: 1px solid var(--grey);

    &:hover {
      opacity: 0.9;
    }
  }
  .checkbox-container-toggle {
    border: 1px solid var(--blue);
    background-color: var(--blue);
    color: var(--white);
  }
  .checkbox-container-loading {
    background-color: var(--light_grey);
    color: var(--dark_grey);
    cursor: progress;
    font-size: 12px;
  }
}
