.company-logo {
  object-fit: contain;
  border-radius: 16%;
  border: 0.5px solid var(--subtitle);
  background-color: var(--white);
}

.company-logo-icon {
  background-color: var(--card);
  color: var(--text);
  display: flex;
  justify-content: center;
  align-items: center;
}
