.auth-layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 0px 0px 0px;
  gap: 40px;

  .auth-layout-card {
    width: 400px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (max-width: 600px) {
      border-radius: 0px;
      background-color: var(--background);
    }

    .auth-layout-card-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 20px;
      padding: 40px 30px 0px 30px;
      .auth-layout-card-back-button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 28px;
        width: 28px;
        color: var(--grey);
        transition: 0.25s;
        border-radius: 4px;
        cursor: pointer;
        &:hover {
          color: white;
          background-color: var(--grey);
          opacity: 0.3;
        }
      }
    }
    .auth-layout-card-body {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 0px 30px;
    }

    .auth-layout-card-footer {
      display: flex;
      flex-direction: row;
      gap: 26px;
      padding: 5px 50px;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      .user-info-selector {
        display: flex;
        flex-direction: row;
        gap: 4px;
        padding: 10px 0px;
        .user-info-selector-select {
          background-color: var(--card);
          color: var(--text);
          border: none;
          cursor: pointer;
          &:focus {
            width: auto;
          }
        }
      }
    }
  }

  .auth-layout-inputs {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .auth-layout-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
}
