@import "styles/_variables.scss";

.split-layout-container {
  display: flex;
  height: calc(100vh - #{$navbar-height});
  padding: 0px 20px;

  .split-layout-left {
    flex: 1;
    overflow-y: auto;
    scrollbar-width: none; /* hide scrollbar */
    padding: 20px 20px 20px 0px;
    max-width: 768px;
    order: 1;
    @media only screen and (min-width: 769px) {
      margin-left: auto;
      margin-right: auto;
    }
    @media only screen and (max-width: 768px) {
      padding: 20px 0px;
    }
  }

  .split-layout-left-full-width {
    max-width: none;
  }

  .split-layout-right {
    flex-basis: 300px;
    border-left: 1px solid var(--border);
    overflow-y: auto;
    scrollbar-width: none; /* hide scrollbar */
    padding: 20px 0px 20px 20px;
    order: 2;
    @media only screen and (max-width: 768px) {
      border: none;
      border-bottom: 1px solid var(--border);
      padding: 20px 0px 20px 0px;
    }
  }

  .split-layout-left::-webkit-scrollbar,
  .split-layout-right::-webkit-scrollbar {
    display: none; /* hide scrollbar */
  }
}

@media only screen and (max-width: 768px) {
  .split-layout-container {
    flex-direction: column;
    height: 100%;
    padding: 20px;

    .split-layout-right {
      flex-basis: auto;
      order: 1;
    }
    .split-layout-order-2 {
      order: 2;
    }
    .split-layout-order-1 {
      order: 1;
    }
  }
}
