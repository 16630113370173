@keyframes pulsate {
  0% {
    background-color: var(--card);
  }

  50% {
    background-color: var(--background);
  }

  100% {
    background-color: var(--card);
  }
}

.notme-loader {
  animation: pulsate 2s infinite ease-in-out;
  border-radius: 8px;
}
