.company-banner {
  .company-banner-body {
    display: flex;
    flex-direction: row;

    p {
      font-size: 14px;
    }

    .company-banner-body-icon-text {
      display: flex;
      flex-direction: row;
      gap: 14px;
    }

    .company-banner-body-title {
      display: flex;
      gap: 8px;
      font-size: 16px;
      margin-bottom: 10px;
    }

    .company-banner-body-icon {
      flex-basis: 26px;
      height: 26px;
      flex-grow: 0;
      flex-shrink: 0;
      font-size: 12px;
      border-radius: 100%;
      border: 1px solid var(--blue);
      cursor: pointer;
      transition: 0.25s;

      vertical-align: middle;
      padding: 0;
      text-align: center;

      &:hover {
        background-color: var(--light_grey);
      }

      &:active {
        background-color: var(--grey);
      }

      .pencil-icon {
        position: relative;
        top: 20%;
        text-decoration: none;
      }
    }
  }
}

.company-banner-modal-remove-company {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.company-banner-modal-text {
  font-size: 14px;
}

.company-banner-modal-icon-text {
  font-size: 14px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.notme-modal__validate {
  &-info {
    display: flex;
    flex-direction: column;
    gap: 12px;
    text-align: center;
    .entity-logo {
      align-self: center;
    }
  }
  &-title {
    font-size: 18px;
    color: var(--text);
  }
  &-description {
    font-size: 14px;
    color: var(--subtitle);
    width: 60%;
    align-self: center;
  }
}
