.report-evidence-icon {
  .report-evidence-image {
    object-fit: cover;
    border: 0.5px solid var(--background);
    border-radius: 4px;
  }
  .report-evidence-image-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    width: 42px;
    border: 0.5px solid var(--background);
    border-radius: 4px;
    background-color: var(--blue);
    color: var(--white);
  }
}
