.contact-list {
  display: flex;
  flex-direction: column;
  gap: 6px;
  .contact-cell {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
  }
}
