.notme-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  span {
    color: var(--subtitle);
  }

  &-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 8px;
    span {
      line-height: 14px;
    }
  }

  .notme-button-dark_blue {
    background-color: var(--card);
    border-color: var(--card);
    color: var(--dark-blue);
    line-height: 18px;
    &-plain {
      background-color: transparent;
      color: var(--subtitle);
    }
    &:disabled {
      opacity: 0.4;
    }
  }

  .fa-chevron-left,
  .fa-chevron-right {
    font-size: 18px;
  }
}
