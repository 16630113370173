.entitlements-cell {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--card);
  border-radius: 8px;
  padding: 8px 16px;
  gap: 10px;

  .entitlements-cell-texts {
    display: flex;
    flex-direction: column;
    gap: 4px;
    .entitlements-cell-title {
      font-family: "Campton-Bold";
      font-size: 18px;
    }
    .entitlements-cell-description {
      font-family: "Campton-Medium";
      font-size: 14px;
      line-height: 18px;
      color: var(--subtitle);
    }
  }

  .entitlements-cell-switch-container {
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
  }
}
