.entity-logo-collapsed {
  position: relative;
  align-self: flex-start;
  width: 40px;
  height: 40px;
  .entity-logo-collapsed-img {
    object-fit: contain;
    border-radius: 8px;
    background-color: var(--white);
  }
  .entity-logo-collapsed-subentities {
    position: absolute;
    border-radius: 4px;
    background-color: var(--border);
    padding: 0px 1px;
    z-index: 2;
    bottom: 0;
    right: 0;
    margin-right: 0px;
    margin-bottom: -5px;
    font-size: 12px;
    color: var(--text);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .entity-logo-collapsed-clear {
    position: absolute;
    border-radius: 3px;
    border: 1px solid var(--border);
    background-color: var(--card);
    padding: 2px 4px;
    z-index: 2;
    top: 0;
    right: 0;
    margin-right: 0px;
    margin-top: -5px;
    font-size: 12px;
    color: var(--red);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}
