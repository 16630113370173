@import "styles/_variables.scss";

.app-layout {
  padding-left: $sidebar-size;
  transition: padding-left 0.2s;
  @media (max-width: 768px) {
    padding-left: 0px;
  }

  .app-layout-container {
    padding-top: $navbar-height;
  }
}
.app-layout-reduced {
  padding-left: $sidebar-size-reduced;
  @media (max-width: 768px) {
    padding-left: 0px;
  }
}

.app-layout-sidebar {
  display: block;
}

.app-layout-sidebar-show {
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
}
