@import "styles/_variables.scss";

.sidebar {
  position: fixed;
  bottom: 0;
  left: 0;
  transition: width 0.2s, left 0.2s;
  width: $sidebar-size;
  top: $navbar-height;
  background-color: var(--card);
  border-right: 0.5px solid var(--border);
  z-index: 90;
  .sidebar-list {
    display: flex;
    flex-direction: column;
    gap: 2px;
    padding: 10px;
    margin-bottom: 0px;
  }

  .sidebar-content {
    overflow-x: hidden;
    height: calc(100vh - 61px - 48px);
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

.sidebar-bottom-buttons-container {
  position: absolute;
  background-color: var(--card);
  width: 100%;
  bottom: 0;
  padding: 4px 10px;
  .sidebar-expand-button {
    @media (max-width: 768px) {
      display: none;
    }
  }
}

.sidebar-reduced {
  width: $sidebar-size-reduced;
}
