.notme-news-section-vertical {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  h5 {
    display: flex;
    align-items: center;
    height: 21px;
  }

  &__container {
    background-color: var(--card);
    border-radius: 8px;
    padding: 8px;
  }
}
