@import "../assets/fonts/CamptonFontKit.css";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");

body {
  margin: 0;
  font-family: "Campton-Medium", "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background);
  color: var(--text);
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Campton-Bold";
  color: var(--text);
  margin: 0px;
}

h1 {
  font-family: "Campton-Medium";
  font-size: 28px;
}
h2 {
  font-size: 26px;
}
h3 {
  font-size: 22px;
}
h4 {
  font-size: 18px;
}

h5 {
  font-size: 14px;
  color: var(--subtitle);
}

h6,
p {
  font-family: "Campton-Medium";
  font-size: 16px;
  margin: 0px;
}

h6 {
  color: var(--subtitle);
}
p {
  color: var(--text);
}
a {
  color: var(--blue);
  text-decoration: none;
  &:hover {
    color: var(--light_blue);
  }
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.subtitle {
  color: var(--subtitle);
}

.header-section-title {
  text-transform: uppercase;
  color: var(--text);
}

.header-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.header-section-action {
  color: var(--blue);
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.notme-modal {
  display: flex;
  flex-direction: column;
  gap: 12px;

  background-color: var(--background);
  padding: 26px;
  border-radius: 10px;
  width: 568px;
  transition: all 1s ease-out;
  max-height: 90vh;

  @media (max-width: 768px) {
    width: calc(100vw - 40px);
    margin: 20px;
  }

  .notme-modal-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .notme-modal-header-close {
      font-size: 16px;
      flex-basis: 26px;
      height: 26px;
      flex-grow: 0;
      flex-shrink: 0;
      vertical-align: middle;
      padding: 0;
      cursor: pointer;
      text-align: center;

      .times-icon {
        position: relative;
        top: 16%;
        text-decoration: none;
      }
    }
  }

  .notme-modal-content {
    overflow-y: scroll;
    max-height: 400px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .notme-modal-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .notme-modal-body-content {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
  }

  .notme-modal-body-content-column {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .notme-modal-footer {
    display: flex;
    gap: 10px;
    flex-direction: row-reverse;
  }
}

.notme-alert {
  width: 368px;
  @media (max-width: 768px) {
    width: auto;
    margin: 20px;
  }
}

.loading-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--grey);
  padding-top: 20px;
}

.Toastify__toast {
  border-radius: 12px !important;
  margin-bottom: 10px !important;

  @media (max-width: 600px) {
    margin: 8px 12px;
  }
}
