.category {
  display: flex;
  flex-direction: column;
  gap: 10px;
  cursor: pointer;
  .category-container {
    position: relative;
    .selected-container {
      position: absolute;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 22px;
      width: 22px;
      margin: -6px;
      border-radius: 6px;
      padding: 3px;
      border: 1px solid var(--card);
      background-color: var(--blue);
      .selected-container-text {
        padding-top: 2px;
        color: var(--card);
        font-family: "Campton-Book";
        font-size: 10px;
      }
    }
    .selected-container-light_blue {
      background-color: var(--light_blue);
    }
    .selected-container-light_blue {
      background-color: var(--blue);
    }
    .selected-container-green {
      background-color: var(--green);
    }
    .selected-container-red {
      background-color: var(--red);
    }
    .selected-container-dark_blue {
      background-color: var(--dark_blue);
    }
    .category-icon-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      height: 60px;
      border: 1px solid var(--blue);
      border-radius: 12px;
      .category-icon {
        filter: invert(14%) sepia(71%) saturate(4638%) hue-rotate(198deg)
          brightness(88%) contrast(76%);
      }
      .category-icon-light_blue {
        filter: invert(79%) sepia(40%) saturate(252%) hue-rotate(158deg)
          brightness(89%) contrast(91%);
      }
      .category-icon-blue {
        filter: invert(25%) sepia(41%) saturate(2054%) hue-rotate(183deg)
          brightness(90%) contrast(83%);
      }
      .category-icon-green {
        filter: invert(62%) sepia(25%) saturate(503%) hue-rotate(102deg)
          brightness(86%) contrast(91%);
      }
      .category-icon-red {
        filter: invert(37%) sepia(27%) saturate(7454%) hue-rotate(344deg)
          brightness(84%) contrast(77%);
      }
      .category-icon-dark_blue {
        filter: invert(9%) sepia(23%) saturate(6033%) hue-rotate(194deg)
          brightness(95%) contrast(104%);
      }
      .category-icon--selected {
        filter: brightness(0) invert(1);
      }
    }

    @mixin category-icon-container-theme-selected($color) {
      border: 1px solid var($color);
      background-color: var($color);
    }

    @mixin category-icon-container-theme($color) {
      border: 1px solid var($color);
    }

    .category-icon-container-light_blue--selected {
      @include category-icon-container-theme-selected(--light_blue);
    }
    .category-icon-container-blue--selected {
      @include category-icon-container-theme-selected(--blue);
    }
    .category-icon-container-green--selected {
      @include category-icon-container-theme-selected(--green);
    }
    .category-icon-container-red--selected {
      @include category-icon-container-theme-selected(--red);
    }
    .category-icon-container-dark_blue--selected {
      @include category-icon-container-theme-selected(--dark_blue);
    }

    .category-icon-container-light_blue {
      @include category-icon-container-theme(--light_blue);
    }
    .category-icon-container-blue {
      @include category-icon-container-theme(--blue);
    }
    .category-icon-container-green {
      @include category-icon-container-theme(--green);
    }
    .category-icon-container-red {
      @include category-icon-container-theme(--red);
    }
    .category-icon-container-dark_blue {
      @include category-icon-container-theme(--dark_blue);
    }
  }
  .category-name {
    font-size: 13px;
    width: 60px;
    text-align: center;
    white-space: normal;
    word-break: break-word;
    color: var(--blue);
  }

  .category-name-light_blue {
    color: var(--light_blue);
  }
  .category-name-light_blue {
    color: var(--blue);
  }
  .category-name-green {
    color: var(--green);
  }
  .category-name-red {
    color: var(--red);
  }
  .category-name-dark_blue {
    color: var(--dark_blue);
  }
}
