.permissions-table-page {
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  gap: 20px;
  .permissions-table {
    width: 100%;

    th,
    td {
      border-bottom: 1px solid #ddd;
    }
    tr:hover {
      background-color: var(--card);
    }

    .table-column {
      width: 12%;
      text-align: center;
      padding-top: 7px;
    }
    .table-column-left {
      padding: 6px 0px 6px 4px;
    }

    .feature-heading {
      align-items: center;
      display: flex;
    }
    .feature-heading-title {
      margin: 0;
      font-family: "Campton-Book";
      font-size: 14px;
      font-weight: 500;
    }
    .feature-heading-title--section {
      font-family: "Campton-Bold";
      font-size: 14px;
    }
  }
}
