.general-page {
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  gap: 20px;

  &-subtitle {
    font-size: 14px;
    font-family: "Campton-Medium";
    color: var(--subtitle);
  }

  &-container-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    .setting-item {
      flex-basis: calc(50% - 10px);

      @media (max-width: 600px) {
        flex-basis: 100%;
      }
    }
  }

  &-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 12px;
  }

  .organization-logo-container {
    display: flex;
    flex-direction: row;
    gap: 6px;

    .organization-logo {
      flex: 0 0 110px;
      width: 110px;
      height: 110px;
      border-radius: 12px;
      background-color: var(--card);
      border: 1px solid var(--border);
      object-fit: cover;
    }
  }

  .email-address-button {
    margin-top: 8px;
    margin-bottom: 12px;
  }

  .email-button-container {
    margin-top: 12px;
    display: flex;
    flex-direction: row;
    gap: 12px;
  }
}
