.just-sayin-create {
  padding: 1rem 0;

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-content {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    &-icon,
    &-text {
      color: var(--subtitle);
      font-size: 16px;
    }
  }
}
