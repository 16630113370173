/**
 * @license
 * MyFonts Webfont Build ID 3888327, 2020-04-07T18:16:29-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: Campton-Black by Rene Bieder
 * URL: https://www.myfonts.com/fonts/rene-bieder/campton/black/
 * 
 * Webfont: Campton-Bold by Rene Bieder
 * URL: https://www.myfonts.com/fonts/rene-bieder/campton/bold/
 * 
 * Webfont: Campton-Book by Rene Bieder
 * URL: https://www.myfonts.com/fonts/rene-bieder/campton/book/
 * 
 * Webfont: Campton-ExtraLight by Rene Bieder
 * URL: https://www.myfonts.com/fonts/rene-bieder/campton/extra-light/
 * 
 * Webfont: Campton-Medium by Rene Bieder
 * URL: https://www.myfonts.com/fonts/rene-bieder/campton/medium/
 * 
 * 
 * Webfonts copyright: Copyright &amp;#x00A9; 2017 by Ren&amp;#x00E9; Bieder. All rights reserved.
 * 
 * © 2020 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/3b54c7");
  
@font-face {
  src: url('webFonts/Campton-Book/font.eot');
  font-family: 'Campton-Book';
  src: url('webFonts/Campton-Book/font.woff2') format('woff2'), url('webFonts/Campton-Book/font.woff') format('woff'), url('webFonts/Campton-Book/font.eot?#iefix') format('embedded-opentype'), url('webFonts/Campton-Book/font.ttf') format('truetype');
}
@font-face {
  src: url('webFonts/Campton-Medium/font.eot');
  font-family: 'Campton-Medium';
  src: url('webFonts/Campton-Medium/font.woff2') format('woff2'), url('webFonts/Campton-Medium/font.woff') format('woff'), url('webFonts/Campton-Medium/font.eot?#iefix') format('embedded-opentype'), url('webFonts/Campton-Medium/font.ttf') format('truetype');
}
@font-face {
  src: url('webFonts/Campton-Bold/font.eot');
  font-family: 'Campton-Bold';
  src: url('webFonts/Campton-Bold/font.woff2') format('woff2'), url('webFonts/Campton-Bold/font.woff') format('woff'), url('webFonts/Campton-Bold/font.eot?#iefix') format('embedded-opentype'), url('webFonts/Campton-Bold/font.ttf') format('truetype');
}
@font-face {
  src: url('webFonts/Campton-ExtraLight/font.eot');
  font-family: 'Campton-ExtraLight';
  src: url('webFonts/Campton-ExtraLight/font.woff2') format('woff2'), url('webFonts/Campton-ExtraLight/font.woff') format('woff'), url('webFonts/Campton-ExtraLight/font.eot?#iefix') format('embedded-opentype'), url('webFonts/Campton-ExtraLight/font.ttf') format('truetype');
}
@font-face {
  src: url('webFonts/Campton-Black/font.eot');
  font-family: 'Campton-Black';
  src: url('webFonts/Campton-Black/font.woff2') format('woff2'), url('webFonts/Campton-Black/font.woff') format('woff'), url('webFonts/Campton-Black/font.eot?#iefix') format('embedded-opentype'), url('webFonts/Campton-Black/font.ttf') format('truetype');
}

