.permissions-users-page {
  .permissions-users-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .permissions-users-list {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
}
