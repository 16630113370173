.social-links {
  display: flex;
  flex-direction: row;
  gap: 6px;
  .social-link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 26px;
    height: 26px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.5s;
  }

  @mixin social-link-theme($color) {
    color: $color;
    &:hover {
      color: white;
      background-color: $color;
    }
  }

  .social-link-twitter {
    @include social-link-theme(#1da1f2);
  }
  .social-link-instagram {
    @include social-link-theme(#e1306c);
  }
  .social-link-facebook {
    @include social-link-theme(#4267b2);
  }
  .social-link-linkedin {
    @include social-link-theme(#0a66c2);
  }
  .social-link-youtube {
    @include social-link-theme(#ff0000);
  }
}
