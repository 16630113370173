.section-tabs-grouped {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
