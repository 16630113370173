.search-entity-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 0px;
  .search-entity-item-img {
    object-fit: contain;
    border-radius: 6px;
    background-color: var(--white);
  }
  .search-entity-item-title {
    padding-top: 2px;
  }
}
