.icon-circle-button {
  flex-basis: 26px;
  height: 26px;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 12px;
  border-radius: 6px;
  border: 1px solid var(--blue);
  cursor: pointer;
  transition: 0.25s;

  vertical-align: middle;
  padding: 0;
  text-align: center;

  &:hover {
    background-color: var(--light_grey);
  }

  &:active {
    background-color: var(--grey);
  }

  .icon {
    position: relative;
    top: 20%;
    text-decoration: none;
  }
}
