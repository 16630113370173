.entity-logo {
  position: relative;
  align-self: flex-start;
  .entity-logo-img {
    object-fit: contain;
    border-radius: 8px;
    background-color: var(--white);
  }
  .subentity-logo-img {
    position: absolute;
    border-radius: 4px;
    border: 1px solid var(--border);
    background-color: var(--white);
    z-index: 2;
    bottom: 0;
    right: 0;
    margin-right: 0px;
    margin-bottom: 0px;
    object-fit: contain;
  }
}

.entity-logo-subcompany {
  width: 48px;
  flex: 0 0 48px;
  height: 45px;
}
