.report-status-icon {
  font-size: 24px;
  color: var(--subtitle);
}

.report-status-icon-bullet {
  position: absolute;
  font-size: 8px;
  border-radius: 10px;
  border: 3px solid var(--card);
  right: -8px;
  bottom: -2px;
}
