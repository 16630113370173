.notme-input {
  flex-direction: column;
  text-align: left;

  .notme-input--label {
    margin-bottom: 0.2em;
    font-family: "Campton-Medium";
    font-size: 16px;
    color: var(--text);
  }

  .notme-input--right-label {
    font-weight: 400;
    color: var(--blue);
    font-size: 14px;
    float: right;
    cursor: pointer;

    &:hover {
      font-weight: 600;
    }
  }

  .notme-input--container {
    position: relative;
    display: flex;
  }

  .notme-input--input-eye {
    cursor: pointer;
    position: absolute;
    display: flex;
    align-items: center;
    right: 10px;
    top: 0px;
    bottom: 0px;
  }

  .notme-input--input-custom-container {
    border: 1px solid var(--dark_grey);
    border-radius: 6px;
    background-color: var(--card);
    padding: 10px 10px 8px 10px;
    width: 100%;
    color: var(--text);

    display: flex;
    flex: 1;
    gap: 10px;

    .notme-input--input-custom {
      font-size: 16px;
      font-family: "Campton-Medium";
      -webkit-appearance: none;
      outline: none;
      background-color: var(--card);
      color: var(--text);
      border: none;
      margin: 2px 0px;
      height: 24px;
      line-height: inherit;
      width: 100%;
      &:focus {
        outline: none;
        border: none;
      }
    }
  }

  .notme-input--input {
    -webkit-appearance: none;
    outline: none;
    color: var(--text);
    border: 1px solid var(--dark_grey);
    border-radius: 6px;
    background-color: var(--card);
    padding: 12px 10px 10px 10px;
    width: 100%;
    font-size: 16px;
    font-family: "Campton-Medium";
    &:focus {
      background-color: var(--card);
      outline: none;
      border: 1px solid var(--blue);
    }
  }
  .notme-input--input-password {
    padding-right: 40px;
  }

  .notme-input--phone-input {
    padding: 12px 10px 10px 60px;
  }

  .notme-input--dropdown {
    background-color: var(--card);
    border-radius: 0px;
    border: 1px solid var(--grey);
    box-shadow: none;
    scrollbar-color: dark;
  }

  select:has(option[value=""]:checked) {
    color: var(--subtitle);
  }

  .notme-input--dropdown-button {
    color: var(--text);
    .country {
      &:hover,
      &:focus {
        background-color: var(--background);
      }
    }
    .country.highlight {
      background-color: var(--background);
    }
  }

  .notme-input--select-icon {
    position: absolute;
    cursor: pointer;
    z-index: 2;
    margin-top: 16px;
    right: 12px;
    height: 16px !important;
    color: var(--dark_grey);
  }

  .notme-input--password-icon {
    position: absolute;
    cursor: pointer;
    z-index: 2;
    margin-top: 13px;
    right: 42px;
    height: 16px !important;
    color: var(--dark_blue);
  }

  .notme-input--error-message {
    text-align: left;
    color: var(--red);
    margin: 5px 0px;
    font-size: 14px;
  }
}
