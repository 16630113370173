.entitlements-page {
  padding: 1rem 0;
  .entitlements-page-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 12px;
    @media (min-width: 576px) {
      height: 40.5px;
      align-items: center;
    }
    @media (max-width: 575px) {
      flex-direction: column;
      gap: 10px;
    }
  }

  .entitlements-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
