.report-step-page {
  position: relative;
  min-height: calc(100vh - 61px);
  .report-step-header {
    display: flex;
    flex-direction: row;
    gap: 20px;
    .report-step-header-back-button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 28px;
      width: 28px;
      color: var(--grey);
      transition: 0.25s;
      border-radius: 4px;
      cursor: pointer;
      &:hover {
        color: white;
        background-color: var(--grey);
        opacity: 0.3;
      }
    }
  }

  .report-step-body {
    padding-bottom: 80px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .report-step-bar {
    display: flex;
    flex-direction: row;
    gap: 6px;
    background-color: var(--card);
    margin: 0 auto;
    width: auto;
    max-width: 744px;
    border-radius: 0px;
    padding: 10px;
    border-radius: 10px;
  }

  .report-step-bar-container {
    position: absolute;
    overflow: hidden;
    left: 0;
    right: 0;
    bottom: 0;
    margin-bottom: 20px;
    transition: 0.5s;
    @media (max-width: 768px) {
      margin-left: 10px;
      margin-right: 10px;
    }

    .report-step-button {
      flex: initial;
    }

    .report-step-progress {
      flex: 1;
      align-self: center;
      .progress {
        background-color: var(--background);
        height: 10px;
      }
    }
  }
}

.report-step-loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--grey);
  padding-top: 100px;
}
