.content-overlay {
  position: fixed;
  display: none;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 80;
  pointer-events: auto;
}

@media (max-width: 767px) {
  .content-overlay-sidebar {
    display: block;
  }
}
