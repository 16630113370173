.permission-invite-cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 600px) {
    flex-direction: column;
    gap: 10px;
  }
  .permission-invite-cell-info-left {
    display: flex;
    flex-direction: row;
    flex: 0 0 auto;
    width: calc(100% - (26px * 3 + 10px * 2));
    gap: 12px;
    @media (max-width: 600px) {
      width: 100%;
    }
    .permission-invite-cell-avatar {
      flex: 0 0 44px;
      align-self: center;
      @media (max-width: 600px) {
        align-self: flex-end;
      }
    }
    .permission-invite-cell-email-role-container {
      flex: 0 0 auto;
      width: calc(100% - 56px);
      display: flex;
      flex-direction: row;
      @media (max-width: 600px) {
        flex-direction: column;
      }
      .permission-invite-cell-email-container {
        width: 75%;
        @media (max-width: 600px) {
          width: 100%;
        }
        .permission-invite-cell-referrer-text {
          font-size: 15px;
          font-family: "Campton-Medium";
          color: var(--subtitle);
        }
      }
      .permission-invite-cell-role-container {
        flex: 0 0 auto;
        width: 25%;
        align-content: center;
        @media (max-width: 600px) {
          width: 100%;
        }
      }
    }
  }

  .permission-invite-cell-buttons-container {
    display: flex;
    gap: 10px;
    flex-direction: row;
    width: 100%;
    @media (max-width: 600px) {
      padding-left: 54px;
    }
  }
}
