.notme-news-section-featured {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .header-section {
    h5 {
      display: flex;
      align-items: center;
      height: 21px;
    }
  }

  &__container {
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;

    // Hide scrollbar for Chrome, Safari and Opera
    &::-webkit-scrollbar {
      display: none;
    }

    // Hide scrollbar for IE, Edge and Firefox
    -ms-overflow-style: none;
    scrollbar-width: none;

    // NewsCell styling adjustment
    .notme-news-cell--featured {
      width: 100%;
      &:not(:last-child):not(:only-child) {
        margin-right: 10px;
      }
      flex-shrink: 0;
      scroll-snap-align: center;
    }
  }

  &__buttons {
    position: absolute;
    top: 50%; // Position at the middle vertically
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    transform: translateY(
      -50%
    ); // To perfectly center vertically, offsetting its own height
  }
}
