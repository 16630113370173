.information-banner {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  gap: 10px;
  border-radius: 10px;

  &--warning {
    background-color: var(--yellow-a10);
    .information-banner__text,
    .information-banner__icon {
      color: var(--yellow);
    }
  }

  &--alert {
    background-color: var(--red-a10);
    .information-banner__text,
    .information-banner__icon {
      color: var(--red);
    }
  }

  &--information {
    background-color: var(--card);
    .information-banner__text,
    .information-banner__icon {
      color: var(--text);
    }
  }

  &--success {
    background-color: var(--green-a10);
    .information-banner__text,
    .information-banner__icon {
      color: var(--green);
    }
  }

  &__text {
    flex-shrink: 1;
    font-size: 14px;
    line-height: 16px;
  }
  &__icon {
    font-size: 18px;
  }
}
