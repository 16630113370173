.bubble-menu-editor {
  border-radius: 6px;
  background-color: var(--text);
  padding: 4px 6px;
  display: flex;
  align-items: center;
  gap: 2px;
  flex-direction: row;

  .separator {
    width: 1px;
    background-color: var(--subtitle);
    height: 20px;
    border-radius: 2px;
  }

  .bubble-menu-editor-button {
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    transition: 0.5s;
    color: var(--background);
    width: 24px;
    height: 24px;
    cursor: pointer;
    font-size: 16px;
    outline: 0;
    background-color: var(--text);
    border: 0px;
    &:hover {
      background-color: var(--subtitle);
    }
  }
  .bubble-menu-editor-button-heading {
    font-size: 14px;
    font-family: "Open Sans";
  }
  .is-active {
    color: var(--yellow);
  }

  .bubble-menu-editor-link-container {
    position: relative;
    display: flex;
    gap: 4px;
    padding: 0px;
    .bubble-menu-editor-link-input {
      background-color: transparent;
      color: var(--card);
      width: 236px;
      height: 22px;
      border: 0px;
      align-self: center;
      &:focus {
        outline: 0px solid transparent;
      }
    }
  }
}
