.editor-container {
  min-height: calc(100vh - 61px- 20px - 50px - 41px);

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    font-family: "Open Sans", sans-serif;
  }

  .editor {
    &:focus {
      outline: 0px solid transparent;
    }
  }
}

.ProseMirror p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.ProseMirror blockquote {
  border-left: 3px solid rgba(13, 13, 13, 0.1);
  padding-left: 1rem;
}

.ProseMirror img {
  height: auto;
  max-width: 100%;
}

.ProseMirror img.ProseMirror-selectednode {
  outline: 3px solid #68cef8;
}
