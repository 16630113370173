.speak-up-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  .speak-up-modal-text-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    white-space: pre-wrap;
  }
  .speak-up-modal-loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--grey);
    padding-top: 20px;
  }
}
