.footer {
  padding-top: 60px;
  .footer-link {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    padding-bottom: 10px;
    color: var(--grey);
    cursor: pointer;
    &:hover {
      font-weight: 800;
    }
    .footer-link-icon {
      width: 22px;
    }
    .footer-link-text {
      word-break: break-word;
      color: var(--grey);
    }
  }
  .store-icons {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    .store-icon {
      object-fit: contain;
    }
  }
}
