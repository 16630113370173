.comment-section {
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding-bottom: 116px;
  
  &__header {
    display: flex;
    flex-direction: column;
    gap: 14px;
  }

  &__counter {
    &-title {
      color: var(--subtitle);
      text-transform: uppercase;
    }
  }

  &__comments {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
