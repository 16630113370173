.report-cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px !important;

  .report-cell-info {
    display: flex;
    flex-direction: row;
    flex: 0 0 auto;
    width: 60%;

    @media (max-width: 600px) {
      width: 60%;
      flex-direction: column;
    }

    .report-name {
      display: flex;
      flex-direction: row;
      gap: 20px;
      align-items: center;

      flex: 0 0 auto;
      width: 45%;
      @media (max-width: 600px) {
        width: 100%;
      }

      .report-name-status-icon {
      }

      .report-name-text {
        font-size: 16px;
      }
    }
    .report-status {
      flex: 0 0 auto;
      width: 55%;
      @media (max-width: 600px) {
        width: 100%;
      }
    }
  }
  .report-cell-company-date {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 0 0 auto;
    width: 40%;

    @media (max-width: 600px) {
      width: 40%;
      flex-direction: column-reverse;
      text-align: right;
      gap: 4px;
    }
    .report-company {
      flex: 0 0 auto;
      width: 50%;
      @media (max-width: 600px) {
        width: 100%;
      }
    }
    .report-date {
      flex: 0 0 auto;
      width: 50%;
      @media (max-width: 600px) {
        width: 100%;
      }
    }
  }
}
