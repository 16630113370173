.post-document {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--card);
  padding: 20px 26px;
  border-radius: 14px;

  &__icon {
    width: 58px;
    height: 58px;
    border-radius: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__icon-image {
    color: var(--white);
    font-size: 22px;
  }

  &__title {
    flex-grow: 1;
    margin: 0 30px;
    font-family: "Campton-Bold";
    color: var(--text);
  }

  &__button {
    .notme-button {
      padding: 6px 12px;
    }
  }
}
