.header-title {
  display: flex;
  flex-direction: row;
  gap: 12px;

  .header-title-back-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 28px;
    width: 28px;
    color: var(--grey);
    transition: 0.25s;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      color: var(--white);
      background-color: var(--grey);
      opacity: 0.3;
    }
  }

  .header-title-info-title-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 8px;
    p {
      font-size: 14px;
    }
  }

  .header-title-info-container {
    padding-top: 4px;
    padding-bottom: 6px;
  }

  .header-title-info {
    color: var(--subtitle);
  }
}
