.report-submitted-mobile {
  height: auto;
  overflow: auto;
  .message-button {
    position: fixed;
    z-index: 3;
    height: 54px;
    width: 54px;
    right: 0;
    bottom: 0;
    margin-right: 12px;
    margin-bottom: 12px;
    font-size: 18px;
    line-height: 18px;
    border-radius: 8px;
  }

  @media (min-width: 576px) {
    display: none;
  }
}
