.just-sayin-page {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 1rem 0;

  &__info {
    color: var(--subtitle);
  }

  &__header-text {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
  }

  &__info-container {
    font-size: 14px;
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
