.topbar-navbar {
  position: fixed;
  width: 100%;
  z-index: 100;
  background-color: var(--card);
  border-bottom: 0.5px solid var(--border);
  align-items: center;
  padding: 0.5rem 1rem;

  .navbar-brand-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
  }

  .navbar-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .sidebar-toggle-button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 44px;
      width: 44px;
      color: var(--text);
      transition: 0.25s;
      border-radius: 6px;
      font-size: 22px;
      cursor: pointer;
      &:hover {
        background-color: var(--light_grey);
      }
      @media (min-width: 768px) {
        display: none;
      }

      .navbar-brand {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .topbar-dropdown-container {
    .topbar-dropdown-button {
      border: none;
      background: none;
      padding: 0px;
    }

    .topbar-dropdown {
      position: absolute;
      width: 210px;
      display: flex;
      flex-direction: column;
      background-color: var(--card);
      border-radius: 10px;
      transition: transform 0.2s ease, opacity 0.2s ease,
        visibility 0s linear 0.2s;
      will-change: transform;
      top: 70px;
      z-index: 10;
      -webkit-box-shadow: var(--shadow);
      -moz-box-shadow: var(--shadow);
      box-shadow: var(--shadow);

      right: 0;
      left: auto;
      margin-right: 1rem;

      opacity: 0;
      visibility: hidden;

      .topbar-dropdown-item {
        border-bottom: 0.5px solid var(--dark_grey);
        padding: 10px;
        text-decoration: none;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        color: var(--text);
        &:hover {
          font-weight: 800;
        }
        &:last-child {
          border: none;
        }
      }
      .topbar-dropdown-item-user {
        padding: 8px 10px;
        text-decoration: none;
        border-bottom: 0.5px solid var(--dark_grey);
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 8px;
        .topbar-dropdown-item-user-avatar {
          flex: 0 0 44px;
        }
        .topbar-dropdown-item-user-title {
          display: flex;
          flex-direction: column;
          word-break: break-word;
          align-items: flex-start;
        }
      }
    }
    .topbar-dropdown.visible {
      transform: translateY(-0.3rem);
      transition: transform 0.2s ease, opacity 0.2s ease,
        visibility 0s linear 0s;
      visibility: visible;
      opacity: 1;
    }
  }
}
